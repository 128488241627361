/* treatmentPatternsSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Thunk */
import { fetchTreatmentPatterns } from "src/redux/thunks/treatment-patterns/treatmentPatternsThunk";

/* Interfaces */
import { BaseSliceProps } from "src/shared/interfaces/common/Slice";
import { BaseChartProps } from "src/shared/interfaces/charts/BaseChart";
import { SankeyDiagramProps } from "src/shared/interfaces/charts/SankeyDiagram";
import { BaseErrorProps } from "src/shared/interfaces/common/Error";
import { convertKeysToCamelCase } from "src/utils/helper/string-transformation";

const initialState: BaseSliceProps<BaseChartProps<SankeyDiagramProps[]>> = {
  data: undefined,
  isLoading: false,
  isUpdating: false,
  error: undefined,
};

export const treatmentPatternsSlice = createSlice({
  name: "treatmentPatterns",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchTreatmentPatterns.pending, (state) => {
      if (state.data === undefined) {
        state.isLoading = true;
      } else {
        state.isUpdating = true;
      }
    });
    builder.addCase(
      fetchTreatmentPatterns.rejected,
      (state, action: PayloadAction<BaseErrorProps | undefined>) => {
        state.isLoading = true;
        state.error = {
          showError: action.payload!.showError,
          message: action.payload!.message,
        };
      }
    );
    builder.addCase(
      fetchTreatmentPatterns.fulfilled,
      (state, action: PayloadAction<BaseChartProps<SankeyDiagramProps[]>>) => {
        state.data = convertKeysToCamelCase(action.payload);
        state.isLoading = false;
        state.isUpdating = false;
        state.error = undefined;
      }
    );
  },
});

/* Reducer */
export default treatmentPatternsSlice.reducer;
