/* useResizeObserver.ts */

/* React */
import { useState, useEffect } from "react";

/* Interface(s) */
interface OffsetProps {
  height: number;
  width: number;
}

const useResizeObserver = (ref: any) => {
  const [offset, setOffset] = useState<OffsetProps>({
    height: 0,
    width: 0,
  });

  useEffect(() => {
    const tmpRef = ref.current;
    const updateOffset = () => {
      if (ref.current) {
        setOffset({
          height: ref.current.offsetHeight,
          width: ref.current.offsetWidth,
        });
      }
    };

    updateOffset();

    const resizeObserver = new ResizeObserver(() => {
      updateOffset();
    });

    if (tmpRef) {
      resizeObserver.observe(tmpRef);
    }

    return () => {
      if (tmpRef) {
        resizeObserver.unobserve(tmpRef);
      }
    };
  }, [ref]);

  return offset;
};

export default useResizeObserver;
