/* error-message.ts */

/**
 * @file error-message.ts
 *
 * @description
 *
 * This is a quick fix. If the error message in backend is not explicitly defined,
 * the error will be returned in an unexpected format of type 'UndefinedErrorProps[]'.
 */

interface UndefinedErrorProps {
  type: string;
  loc: string[];
  msg: string;
  input: null;
}

export const transformErrorMessage = (
  detail: string | UndefinedErrorProps[]
) => {
  if (Array.isArray(detail)) {
    return detail[0].msg;
  }
  return detail as string;
};
