/* React */
import React from "react";

/* Stylesheet */
import styles from "./LabelPrimary.module.scss";

/* Interfaces */
interface FilterLabelProps {
  scssProps?: ScssProps;
  label: string;
}

interface ScssProps {
  margin?: string;
  color?: string;
}

const LabelPrimary: React.FC<FilterLabelProps> = ({ scssProps, label }) => {
  return (
    <div
      style={{
        margin: scssProps?.margin,
      }}
      className={styles.label_primary}
    >
      <div style={{ color: scssProps?.color }} className={styles.label}>
        {label}
      </div>
    </div>
  );
};

export default LabelPrimary;
