/* React */
import React from "react";

/* Ionic */
import { IonButton, IonIcon } from "@ionic/react";

/* Stylesheet */
import styles from "./PrimaryButton.module.scss";

/* Interfaces */
interface PrimaryButtonProps {
  scssProps?: ScssProps;
  type: "submit" | "button";
  ariaLabel: string;
  icon?: string;
  label: string;
  disabled?: boolean;
  onClick?: () => void;
}

interface ScssProps {
  marginLeft?: string;
  height?: string;
  width?: string;
  color?: string;
  backgroundColor?: string;
}

const PrimaryButton: React.FC<PrimaryButtonProps> = ({
  scssProps,
  type,
  ariaLabel,
  icon,
  label,
  disabled,
  onClick,
}) => {
  return (
    <IonButton
      style={{
        marginLeft: scssProps?.marginLeft,
        height: scssProps?.height,
        width: scssProps?.width,
        color: scssProps?.color,
        "--background": scssProps?.backgroundColor,
      }}
      className={styles.primary_button}
      type={type}
      aria-label={ariaLabel}
      expand="block"
      disabled={disabled}
      onClick={onClick}
    >
      {icon ? (
        <IonIcon className={styles.icon} slot="start" icon={icon} />
      ) : undefined}
      <div style={{ color: scssProps?.color }} className={styles.label}>
        {label}
      </div>
    </IonButton>
  );
};

export default PrimaryButton;
