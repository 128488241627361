/* useChartLoadingScreen.ts */

/* React */
import { useEffect } from "react";

/* React-Redux */
import { useDispatch } from "react-redux";
import {
  setIsLoading,
  setIsUpdating,
} from "src/redux/features/loading-screen/loadingScreenSlice";

const useChartLoadingScreen = (isLoading: boolean, isUpdating: boolean) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsLoading(isLoading!));
  }, [dispatch, isLoading]);

  useEffect(() => {
    dispatch(setIsUpdating(isUpdating!));
  }, [dispatch, isUpdating]);
};

export default useChartLoadingScreen;
