/* React */
import React from "react";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Components */
import QueryList from "src/components/insights/query-list-container/query-list/QueryList";

/* Stylesheet */
import styles from "./QueryListContainer.module.scss";

const QueryListContainer: React.FC = () => {
  const mainConditionState = useSelector(
    (state: RootState) => state.mainCondition
  );

  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const customCohortsState = useSelector(
    (state: RootState) => state.customCohorts
  );

  return (
    <div className={styles.query_list_container}>
      <QueryList
        states={{
          mainCondition: mainConditionState,
          insightsFilter: insightsFilterState,
          customCohorts: customCohortsState,
        }}
      />
    </div>
  );
};

export default QueryListContainer;
