/* React */
import React from "react";

/* Ionic */
import { IonIcon } from "@ionic/react";
import { close, flash } from "ionicons/icons";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setShowModal } from "src/redux/features/insights/modalSlice";

/* Component */
import PrimaryFabButton from "src/components/shared/buttons/primary-fab-button/PrimaryFabButton";

/* Stylesheet */
import styles from "./CustomCohortsWrapper.module.scss";

/* Interface(s) */
interface CustomCohortsWrapperProps {
  label: string;
  children: React.ReactNode[];
}

const CustomCohortsWrapper: React.FC<CustomCohortsWrapperProps> = ({
  label,
  children,
}) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.custom_cohorts_wrapper}>
      <div className={styles.label_container}>
        <div className={styles.label}>
          <IonIcon className={styles.icon} icon={flash} />
          {label}
        </div>
        <PrimaryFabButton
          scssProps={{
            margin: "0px",
            background: "transparent",
          }}
          icon={close}
          ariaLabel={"Close"}
          onClick={() => dispatch(setShowModal(false))}
        />
      </div>
      <div className={styles.children_container}>{children[0]}</div>
      <div className={styles.controls_container}>{children[1]}</div>
    </div>
  );
};

export default CustomCohortsWrapper;
