/* React */
import React, { useRef } from "react";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import useResizeObserver from "src/utils/hooks/useResizeObserver";
import useBasicChartTooltip from "src/utils/hooks/tooltip/useBasicChartTooltip";
import { config, sankeyBoxplotLayout } from "src/utils/config/chart-config";

/* Component(s) */
import ChartTooltip from "src/components/insights/charts/chart-tooltip/ChartTooltip";
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Stylesheet */
import styles from "./Boxplot.module.scss";

/* Interfaces */
import { SankeyBoxplotProps } from "src/shared/interfaces/charts/SankeyDiagram";
import { ChartLabelProps } from "src/shared/interfaces/common/ChartLabel";
import { createLegendInformation } from "src/utils/helper/legend";

const Boxplot: React.FC<{
  data: SankeyBoxplotProps;
}> = ({ data }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const offset = useResizeObserver(ref);
  const { tooltip, handleOnHover, handleOnUnhover } =
    useBasicChartTooltip(offset);

  const boxplot = (
    <div ref={ref}>
      <Plot
        className={styles.boxplot}
        data={data.data}
        config={config}
        layout={sankeyBoxplotLayout}
        useResizeHandler={true}
        onHover={(event) => handleOnHover(event)}
        onUnhover={handleOnUnhover}
      />
      <ChartTooltip tooltip={tooltip} />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    label: {
      name: data.name,
    },
    chart: boxplot,
    legend: createLegendInformation(data.data),
  };

  return (
    <ChartWrapper
      scssProps={{ height: "auto", boxShadow: "none" }}
      label={chartLabel}
    >
      {boxplot}
    </ChartWrapper>
  );
};

export default Boxplot;
