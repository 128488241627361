/* string-transform.ts */

/* Lodash */
import { camelCase, isArray, isObject, mapKeys } from "lodash";

export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const capitalizeCamelCaseFirstLetter = (str: string) => {
  return str
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .split(" ")
    .map((word) => capitalizeFirstLetter(word))
    .join(" ");
};

export const convertKeysToCamelCase = <T>(object: T): T => {
  if (isArray(object)) {
    // Recursion for arrays
    return object.map((item) => convertKeysToCamelCase(item)) as unknown as T;
  } else if (isObject(object)) {
    // Recursion for objects
    const camelCasedObject = mapKeys(object, (_value, key) => camelCase(key));
    return Object.keys(camelCasedObject).reduce((result, key) => {
      result[key] = convertKeysToCamelCase(camelCasedObject[key]);
      return result;
    }, {} as any) as T;
  }
  return object;
};
