/* React */
import React, { useEffect, useRef } from "react";

/* Ionic */
import { person } from "ionicons/icons";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";

import {
  setOptions,
  clearOptions,
} from "src/redux/features/insights/insights-filter/insightsFilterOptionsSlice";

import { RootState } from "src/redux/store";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import useResizeObserver from "src/utils/hooks/useResizeObserver";
import useBasicChartTooltip from "src/utils/hooks/tooltip/useBasicChartTooltip";
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";
import { config, scatterPlotLayout } from "src/utils/config/chart-config";
import { createSurvivalPlotLayout } from "src/utils/helper/chart-helper";
import { createLegendInformation } from "src/utils/helper/legend";

/* Component(s) */
import ChartTooltip from "src/components/insights/charts/chart-tooltip/ChartTooltip";

/* Stylesheet */
import styles from "./SurvivalPlot.module.scss";

/* Interface(s) */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interfaces/charts/BaseChart";

import { SurvivalPlotProps } from "src/shared/interfaces/charts/SurvivalPlot";
import { ChartLabelProps } from "src/shared/interfaces/common/ChartLabel";

const SurvivalPlot: React.FC<{
  index: number;
  stacked: boolean;
  data: BaseChartProps<SurvivalPlotProps[]>;
  showLabel?: boolean;
}> = ({ index, stacked, data }) => {
  const dispatch = useDispatch();

  const ref = useRef<HTMLDivElement | null>(null);
  const offset = useResizeObserver(ref);
  const { tooltip, handleOnHover, handleOnUnhover } =
    useBasicChartTooltip(offset);

  const survivalPlotOptionsState = useSelector(
    (state: RootState) => state.survivalAnalysisOptions
  );

  const { showConfidenceIntervals } = survivalPlotOptionsState;

  useEffect(() => {
    dispatch(setOptions(ChartType.SCATTER));
    return () => {
      dispatch(clearOptions());
    };
  }, [dispatch]);

  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const { cohorts } = insightsFilterState;

  const survivalPlotData = data.data
    .filter((data: SurvivalPlotProps) => data.trace)
    .map((data: SurvivalPlotProps, index: number) => {
      return {
        ...createSurvivalPlotLayout(index, data, showConfidenceIntervals),
      };
    });

  const survivalPlot = (
    <div ref={ref}>
      <Plot
        className={styles.survival_plot}
        data={
          stacked
            ? survivalPlotData?.flatMap((data: SurvivalPlotProps) => [
                data.trace,
                data.censored,
                data.ciUpper,
                data.ciLower,
              ])
            : [survivalPlotData[index].trace]
        }
        config={config}
        layout={scatterPlotLayout}
        useResizeHandler={true}
        onHover={(event) => {
          handleOnHover(event);
        }}
        onUnhover={handleOnUnhover}
      />
      <ChartTooltip tooltip={tooltip} />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    label: { icon: person, total: data.total!, name: "Patients" },
    featureOrCohort: { name: cohorts.value! },
    chart: survivalPlot,
    legend: createLegendInformation(survivalPlotData),
  };

  return <ChartWrapper label={chartLabel}>{survivalPlot}</ChartWrapper>;
};

export default SurvivalPlot;
