/* React */
import React from "react";

/* Ionic */
import { IonModal } from "@ionic/react";

/* Redux-Toolkit */
import { useDispatch, useSelector } from "react-redux";
import { setShowModal } from "src/redux/features/insights/modalSlice";
import { setCustomCohortsStr } from "src/redux/features/custom-cohorts/customCohortsSlice";
import { AppDispatch, RootState } from "src/redux/store";

/* Utils */
import CustomCohortsWrapper from "src/utils/wrapper/custom-cohorts-wrapper/CustomCohortsWrapper";

/* Component(s) */
import QueryBuilder from "src/components/insights/custom-cohorts/query-builder/QueryBuilder";
import PrimaryButton from "src/components/shared/buttons/primary-button/PrimaryButton";

/* Stylesheet */
import styles from "./CustomCohorts.module.scss";

const CustomCohorts: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const modalState = useSelector((state: RootState) => state.modal);
  const { showModal } = modalState;

  const customCohortsState = useSelector(
    (state: RootState) => state.customCohorts
  );

  const { name, entity, dateRestriction, value } = customCohortsState;

  return (
    <IonModal
      data-testid="custom-cohorts"
      className={styles.custom_cohorts}
      isOpen={showModal}
      onDidDismiss={() => dispatch(setShowModal(false))}
    >
      <CustomCohortsWrapper label={name}>
        <QueryBuilder />
        <PrimaryButton
          scssProps={{
            marginLeft: "auto",
            height: "42px",
            width: "221px",
          }}
          type={"submit"}
          ariaLabel={"Query"}
          label={"Query"}
          onClick={() => {
            dispatch(
              setCustomCohortsStr({
                [name]: {
                  entity_name: entity.name,
                  date_restriction: dateRestriction,
                  value,
                },
              })
            );
            dispatch(setShowModal(false));
          }}
        />
      </CustomCohortsWrapper>
    </IonModal>
  );
};

export default CustomCohorts;
