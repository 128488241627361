/* featureTypesSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Thunk */
import { fetchFeatureTypes } from "src/redux/thunks/options/featureTypesThunk";

/* Interfaces */
import { BaseSliceProps } from "src/shared/interfaces/common/Slice";
import { EntityProps } from "src/shared/interfaces/api/CustomCohorts";
import { BaseErrorProps } from "src/shared/interfaces/common/Error";

const initialState: BaseSliceProps<EntityProps[]> = {
  data: undefined,
  isLoading: false,
  isUpdating: false,
  error: undefined,
};

export const featureTypesSlice = createSlice({
  name: "featureTypes",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFeatureTypes.pending, (state) => {
      if (state.data === undefined) {
        state.isLoading = true;
      } else {
        state.isUpdating = true;
      }
    });
    builder.addCase(
      fetchFeatureTypes.rejected,
      (state, action: PayloadAction<BaseErrorProps | undefined>) => {
        state.isLoading = true;
        if (action.payload) {
          state.error = {
            timestamp: action.payload.timestamp,
            showError: action.payload.showError,
            message: action.payload.message,
          };
        }
      }
    );
    builder.addCase(
      fetchFeatureTypes.fulfilled,
      (state, action: PayloadAction<EntityProps[]>) => {
        state.data = action.payload;
        state.isLoading = false;
        state.error = undefined;
      }
    );
  },
});

/* Reducer */
export default featureTypesSlice.reducer;
