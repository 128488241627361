/* modalSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ModalProps {
  showModal: boolean;
}

const initialState: ModalProps = {
  showModal: false,
};

export const modalSlice = createSlice({
  name: "modalSlice",
  initialState: initialState,
  reducers: {
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
    },
  },
});

/* Action */
export const { setShowModal } = modalSlice.actions;

/* Reducer */
export default modalSlice.reducer;
