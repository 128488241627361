/* React */
import React from "react";

/* Stylesheet */
import styles from "./ValueContainer.module.scss";

/* Interface */
interface ValueContainerProps {
  min: number;
  max: number;
}

const ValueContainer: React.FC<ValueContainerProps> = ({ min, max }) => {
  return (
    <div className={styles.value_container}>
      <span>{min}</span>
      <span>{max}</span>
    </div>
  );
};

export default ValueContainer;
