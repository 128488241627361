/* React */
import React from "react";

/* Ionic */
import { IonIcon } from "@ionic/react";

/* Stylesheet */
import styles from "./TotalContainer.module.scss";

/* Interface */
import { ChartLabelProps } from "src/shared/interfaces/common/ChartLabel";

const TotalContainer: React.FC<{ label?: ChartLabelProps }> = ({ label }) => {
  return (
    <div className={styles.total_container}>
      {label?.label?.icon ? (
        <IonIcon className={styles.icon} icon={label.label?.icon} />
      ) : undefined}
      <div className={styles.label}>
        <div className={styles.patients}>{label?.label?.name}</div>
        <div className={styles.total}>{label?.label?.total}</div>

        {label?.label?.overall ? (
          <div className={styles.overall}>(of {label?.label?.overall})</div>
        ) : undefined}
      </div>
    </div>
  );
};

export default TotalContainer;
