/* React */
import React from "react";

/* Ionic */
import { home, help } from "ionicons/icons";

/* React-Router */
import { useHistory } from "react-router";

/* Component */
import PrimaryFabButton from "src/components/shared/buttons/primary-fab-button/PrimaryFabButton";

/* Stylesheet */
import styles from "./Controls.module.scss";

const Controls: React.FC = () => {
  const history = useHistory();

  const goToPageHome = () => {
    history.push("/");
  };

  return (
    <div className={styles.controls}>
      <PrimaryFabButton
        scssProps={{
          margin: "0px 6px 4px 0px",
          color: "var(--ion-color-text-primary)",
          background: "var(--ion-background-secondary)",
        }}
        ariaLabel={"Home"}
        icon={home}
        onClick={goToPageHome}
      />
      <PrimaryFabButton
        scssProps={{
          margin: "0px 0px 0px 6px",
          color: "var(--ion-color-text-primary)",
          background: "var(--ion-background-secondary)",
        }}
        ariaLabel={"Help"}
        disabled={true}
        icon={help}
      />
    </div>
  );
};

export default Controls;
