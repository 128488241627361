/* Error.ts */

export enum ErrorType {
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

export interface BaseErrorProps {
  timestamp?: string;
  showError: boolean;
  message?: string;
}
