/* React */
import React, { useEffect } from "react";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";
import { setInsightsFilterValue } from "src/redux/features/insights/insights-filter/insightsFilterSlice";

/* Thunks */
import { fetchMainConditions } from "src/redux/thunks/options/mainConditionThunk";
import {
  fetchInsightsFilterCategorical,
  fetchInsightsFilterNumeric,
} from "src/redux/thunks/insights-filter/insightsFilterThunks";

/* Components */
import MainCondition from "src/components/insights/insights-filter/main-condition/MainCondition";
import SelectContainer from "src/components/shared/select-container/SelectContainer";
import TimePeriodSlider from "src/components/insights/insights-filter/time-period-slider/TimePeriodSlider";

/* Interfaces */
import {
  FilterComponentType,
  FilterProps,
} from "src/shared/interfaces/common/InsightsFilter";

const InsightsFilter: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // mainConditionState
  const mainConditionState = useSelector(
    (state: RootState) => state.mainCondition
  );

  // insightsFilterState
  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const handleValue = (key: string, value: string) => {
    dispatch(setInsightsFilterValue({ key: key, value: value }));
  };

  useEffect(() => {
    dispatch(fetchMainConditions());

    if (mainConditionState.value) {
      dispatch(fetchInsightsFilterCategorical(mainConditionState.value));
      dispatch(fetchInsightsFilterNumeric(mainConditionState.value));
    }
  }, [dispatch, mainConditionState.value]);

  return (
    <React.Fragment>
      <MainCondition item={mainConditionState} />
      {Object.entries(insightsFilterState).map(
        ([key, item]: [string, FilterProps<string>], index) => {
          if (item.componentType === FilterComponentType.DROPDOWN) {
            return (
              <SelectContainer
                key={index}
                itemKey={key}
                item={item}
                handleValue={handleValue}
                disabled={item.disabled}
              />
            );
          }
        }
      )}
      <TimePeriodSlider
        insightsFilter={insightsFilterState}
        handleValue={handleValue}
      />
    </React.Fragment>
  );
};

export default InsightsFilter;
