/* React */
import React from "react";

/* Ionic */
import { IonMenuToggle } from "@ionic/react";

/* Component */
import NavItem from "src/components/shared/nav-item/NavItem";

/* Constants */
import { MAIN_MENU_NAV_ITEMS } from "src/shared/constants/NavItems";

/* Stylesheet */
import styles from "./NavContainer.module.scss";

/* Interface */
import { NavItemProps } from "src/shared/interfaces/common/NavItem";

const NavContainer: React.FC = () => {
  const navItems = MAIN_MENU_NAV_ITEMS;

  return (
    <div className={styles.nav_container}>
      <div className={`${styles.title} title gradient`}>syndena Insights</div>
      <IonMenuToggle>
        {navItems.map((item: NavItemProps, index: number) => {
          return (
            <NavItem
              key={index}
              item={item}
              showLines={false}
              showName={true}
              scssProps={{
                paddingStart: "32px",
                activeColor: "var(--ion-gradient-secondary)",
              }}
            />
          );
        })}
      </IonMenuToggle>
    </div>
  );
};

export default NavContainer;
