/* React */
import React from "react";

/* Ionic */
import { IonRange } from "@ionic/react";

/* Stylesheet */
import styles from "./TimePeriodSlider.module.scss";

/* Interfaces */
import { InsightsFilterProps } from "src/shared/interfaces/common/InsightsFilter";

interface TimePeriodProps {
  insightsFilter: InsightsFilterProps;
  handleValue: (key: string, value: string) => void;
}

const TimePeriod: React.FC<TimePeriodProps> = ({
  insightsFilter,
  handleValue,
}) => {
  const { firstYear, lastYear } = insightsFilter;

  // convert firstYear and lastYear to integers
  const firstYearInitialValue = parseInt(firstYear.initialValue!, 10);
  const lastYearInitialValue = parseInt(lastYear.initialValue!, 10);
  const firstYearValue = parseInt(firstYear.value!, 10);
  const lastYearValue = parseInt(lastYear.value!, 10);

  return (
    <div className={styles.time_period_slider}>
      <IonRange
        className={"range"}
        min={firstYearInitialValue}
        max={lastYearInitialValue}
        pin={true}
        ticks={true}
        dualKnobs={true}
        disabled={firstYear.initialValue === lastYear.initialValue}
        value={{
          lower:
            firstYearValue === undefined
              ? firstYearInitialValue
              : firstYearValue,
          upper:
            lastYearValue === undefined ? lastYearInitialValue : lastYearValue,
        }}
        onIonChange={(e) => {
          const { lower, upper } = e.detail.value as {
            lower: number;
            upper: number;
          };

          if (lower > upper) {
            // if lower is greater than upper, set lower to upper and upper to lower
            handleValue("firstYear", upper.toString());
            handleValue("lastYear", upper.toString());
          } else {
            handleValue("firstYear", lower.toString());
            handleValue("lastYear", upper.toString());
          }
        }}
      />
      <div className={styles.label_container}>
        <span className={styles.value}>{firstYear.initialValue}</span>
        <span className={styles.value}>{lastYear.initialValue}</span>
      </div>
    </div>
  );
};

export default TimePeriod;
