/* React */
import React from "react";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setEntity } from "src/redux/features/custom-cohorts/customCohortsSlice";

/* Component */
import Select from "src/components/shared/select/Select";

/* Stylesheet */
import styles from "./CustomEntities.module.scss";

/* Interfaces */
import { EntityProps } from "src/shared/interfaces/api/CustomCohorts";

interface CustomEntitiesProps {
  entity: EntityProps;
  featureTypes: EntityProps[];
}

const CustomEntities: React.FC<CustomEntitiesProps> = ({
  entity,
  featureTypes,
}) => {
  const dispatch = useDispatch();

  const handleOnClick = (item: EntityProps) => {
    dispatch(setEntity(item));
  };

  return (
    <div className={styles.custom_entities}>
      <Select
        label={"Entity name"}
        value={entity.name}
        items={featureTypes}
        onClick={handleOnClick}
      />
    </div>
  );
};

export default CustomEntities;
