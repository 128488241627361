/* React */
import React from "react";

/* Component(s) */
import LabelContainer from "src/components/insights/charts/chart-tooltip/label-container/LabelContainer";
import Hovertext from "src/components/insights/charts/chart-tooltip/hovertext/Hovertext";

/* Stylesheet */
import styles from "./ChartTooltip.module.scss";

/* Interface(s) */
import {
  TooltipProps,
  ContentProps,
} from "src/shared/interfaces/charts/ChartTooltip";

interface ChartTooltipProps {
  tooltip: TooltipProps;
}

const ChartTooltip: React.FC<ChartTooltipProps> = ({ tooltip }) => {
  const { isVisible, reachedTop, reachedRightEdge, content, x, y } = tooltip;

  return (
    <div
      style={{
        visibility: isVisible ? "visible" : "hidden",
        top: y,
        left: x,
        transform: reachedRightEdge
          ? `translate(calc(-100% - 12px), calc(-100% + 21px))`
          : reachedTop
          ? `translate(0, calc(0% - 21px))`
          : `translate(0, calc(-100% + 21px))`,
      }}
      className={`${styles.chart_tooltip} ${
        reachedRightEdge
          ? styles.right_edge
          : reachedTop
          ? styles.top
          : undefined
      }`}
    >
      {content?.map((content: ContentProps, index: number) => {
        return (
          <div className={styles.content_container} key={index}>
            <LabelContainer index={index} content={content} />
            <Hovertext index={index} content={content} />
          </div>
        );
      })}
    </div>
  );
};

export default ChartTooltip;
