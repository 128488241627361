/* React */
import React from "react";

/* Ionic */
import { IonGrid, IonRow, IonCol } from "@ionic/react";

/* Utils */
import { statsTransformed } from "src/utils/chart/stats-container";

/* Constants */
import { GENERIC_GRADIENT } from "src/shared/constants/Colors";

/* Stylesheet */
import styles from "./StatsSlide.module.scss";

/* Interface */
import { StatsProps } from "src/shared/interfaces/charts/Boxplot";

const StatsSlide: React.FC<{ activeIndex: number; stats: StatsProps }> = ({
  activeIndex,
  stats,
}) => {
  const colors: string[] = GENERIC_GRADIENT;
  const transformedStats = statsTransformed(stats);

  return (
    <IonGrid className={styles.stats_slide}>
      {transformedStats.map((stats, index: number) => {
        return (
          <IonRow
            key={index}
            className={styles.row}
            style={{
              height: `calc(100% / ${transformedStats.length})`,
              background: index % 2 === 0 ? "#ffffff" : undefined,
            }}
          >
            <IonCol className={`${styles.col} ${styles.key_col}`}>
              {stats.key}
            </IonCol>
            <IonCol className={`${styles.col} ${styles.value_col}`}>
              <div
                style={{ background: colors[activeIndex] }}
                className={styles.value}
              >
                <div className={styles.value}>{stats.value}</div>
              </div>
            </IonCol>
          </IonRow>
        );
      })}
    </IonGrid>
  );
};

export default StatsSlide;
