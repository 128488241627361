/* featureTypeThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { BaseErrorProps } from "src/shared/interfaces/common/Error";

/* Axios */
import axiosInterceptor from "src/utils/helper/axios-interceptor";

/* Luxon */
import { DateTime } from "luxon";

/* Utils */
import { transformErrorMessage } from "src/utils/helper/error-message";

/* Interface */
import {
  EntityType,
  EntityProps,
} from "src/shared/interfaces/api/CustomCohorts";

export const fetchFeatureTypes = createAsyncThunk<
  EntityProps[],
  string,
  { rejectValue: BaseErrorProps }
>("options/fetchFeatureTypes", async (params: string, { rejectWithValue }) => {
  try {
    const response = await axiosInterceptor.get("/options/features/types", {
      params: {
        main_condition: params,
      },
    });
    const transformedData: EntityProps[] = [];
    Object.keys(response.data).forEach((key: string) => {
      const type: EntityType = response.data[key].type;
      transformedData.push({
        name: key,
        type: response.data[key].type,
        options:
          type === EntityType.BOOLEAN
            ? [true, false]
            : type === EntityType.NUMERIC
            ? []
            : response.data[key].options,
      });
    });
    return transformedData;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      const message: string = transformErrorMessage(
        error.response?.data.detail
      );
      return rejectWithValue({
        timestamp: DateTime.now().toISO(),
        showError: true,
        message: message,
      });
    }
    return rejectWithValue({
      timestamp: DateTime.now().toISO(),
      showError: true,
      message: "An unexpected error occured",
    });
  }
});
