/* insights-filter.ts */

export const defaultCohort = (cohort: string): string => {
  return cohort === "Full sample" ? "" : cohort;
};

export const defaultStartDate = (
  year: string | undefined,
  initialYear: string
): string => {
  return year && year.trim() !== "" ? `${year}-01-01` : `${initialYear}-01-01`;
};

export const defaultEndDate = (
  year: string | undefined,
  initialYear: string
): string => {
  return year && year.trim() !== "" ? `${year}-12-31` : `${initialYear}-12-31`;
};
