/**
 * @file complexLayout.tsx
 *
 * @description
 *
 */

/* React */
import React from "react";

/* Components */
import SankeyDiagram from "src/components/insights/charts/sankey-diagram/SankeyDiagram";
import BoxplotSwiper from "src/components/insights/charts/sankey-diagram/boxplot-swiper/BoxplotSwiper";
import Table from "src/components/shared/table/Table";

/* Interfaces */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interfaces/charts/BaseChart";

import { SankeyDiagramProps } from "src/shared/interfaces/charts/SankeyDiagram";

const createComponents = (
  type: string,
  outerIndex: number,
  innerIndex: number,
  data: BaseChartProps<SankeyDiagramProps[]>
) => {
  const tableData = data.data
    .filter((data) => data.responseTable)
    .map((data) => {
      return {
        ...data.responseTable,
        name: data.name,
      };
    });
  switch (type) {
    case ChartType.SANKEY:
      return [
        {
          component: (
            <SankeyDiagram
              key={`sankey-${outerIndex}`}
              index={outerIndex}
              total={data.total}
              data={data.data[outerIndex]}
            />
          ),
          fullWidth: true,
        },
      ];
    case ChartType.TABLE: {
      return [
        {
          component: (
            <Table
              key={`table-${outerIndex}`}
              index={outerIndex}
              dataKey={"responseTable"}
              stacked={false}
              data={tableData}
            />
          ),
          fullWidth: true,
        },
      ];
    }
    case ChartType.BOX:
      return [
        {
          component: <BoxplotSwiper data={data.data[outerIndex].boxplot} />,
          fullWidth: true,
        },
      ];
    default:
      return [];
  }
};

const createRows = (types: string[][]) => {
  return types?.flatMap((typeArray: string[], outerIndex: number) => {
    const uniqueTypes: string[] = [...new Set(typeArray)];
    return uniqueTypes.map((row: string, innerIndex: number) => ({
      row,
      outerIndex,
      innerIndex,
    }));
  });
};

const createCols = (
  rows: { row: string; outerIndex: number; innerIndex: number }[],
  data: BaseChartProps<SankeyDiagramProps[]>
) => {
  return rows?.map((row) => {
    return {
      cols: createComponents(row.row, row.outerIndex, row.innerIndex, data),
    };
  });
};

export const complexLayout = (
  types: string[][],
  data: BaseChartProps<SankeyDiagramProps[]>
) => {
  const rows = createRows(types);
  const cols = createCols(rows, data);
  return { rows: cols };
};
