/* React */
import React from "react";

/* Ionic */
import { IonIcon } from "@ionic/react";
import { person } from "ionicons/icons";

/* Utils */
import { capitalizeFirstLetter } from "src/utils/helper/string-transformation";

/* Stylesheet */
import styles from "./Cohort.module.scss";

/* Interface */
interface CohortProps {
  name?: string;
}

const Cohort: React.FC<CohortProps> = ({ name }) => {
  return (
    <div className={styles.cohort}>
      <div className={styles.name}>
        <IonIcon className={styles.icon} icon={person} />
        {name === null ? "Full sample" : capitalizeFirstLetter(name!)}
      </div>
    </div>
  );
};

export default Cohort;
