/* React */
import React from "react";

/* Ionic */
import { IonGrid, IonRow, IonCol } from "@ionic/react";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Component */
import QueryListContainer from "src/components/insights/query-list-container/QueryListContainer";

/* Stylesheet */
import styles from "./ChartLayoutWrapper.module.scss";

/* Interfaces */
import { LayoutProps } from "src/shared/interfaces/common/Layout";

interface ChartWrapperProps {
  layout: LayoutProps;
}

const ChartLayoutWrapper: React.FC<ChartWrapperProps> = ({ layout }) => {
  const loadingState = useSelector((state: RootState) => state.loadingScreen);
  const { showContent } = loadingState;

  return showContent ? (
    <div className={styles.chart_layout_wrapper}>
      <QueryListContainer />
      <IonGrid className={styles.grid_container}>
        {layout.rows?.map((row, rowIndex) => {
          return (
            <IonRow
              key={rowIndex}
              style={{
                // if rowIndex is uneven, direction of row is reversed
                flexDirection: rowIndex % 2 !== 0 ? "row-reverse" : undefined,
              }}
              className={styles.row}
            >
              {row.cols.map((col, colIndex) => {
                return (
                  <IonCol
                    key={colIndex}
                    className={styles.col}
                    size={
                      col.fullWidth ? "12" : col.size ? col.size : undefined
                    }
                  >
                    {col.component}
                  </IonCol>
                );
              })}
            </IonRow>
          );
        })}
      </IonGrid>
    </div>
  ) : undefined;
};

export default ChartLayoutWrapper;
