/* customCohortsSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Interface(s) */
import { DateRestrictionType } from "src/shared/interfaces/custom-cohorts/DateRestriction";

import {
  CustomCohortsProps,
  CustomCohortsStrProps,
  EntityProps,
  EntityType,
} from "src/shared/interfaces/api/CustomCohorts";

const initialState: CustomCohortsProps = {
  name: "Custom Cohort",
  entity: {
    type: EntityType.BOOLEAN,
    name: "Heart Failure",
    options: [true, false],
  },
  dateRestriction: DateRestrictionType.FIRST,
  value: true,
  customCohortsStr: undefined,
};

export const customCohortsSlice = createSlice({
  name: "customCohorts",
  initialState: initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setEntity: (state, action: PayloadAction<EntityProps>) => {
      state.entity = action.payload;
    },
    setDateRestriction: (state, action: PayloadAction<DateRestrictionType>) => {
      state.dateRestriction = action.payload;
    },
    setValue: (state, action: PayloadAction<boolean | string>) => {
      state.value = action.payload;
    },
    setCustomCohortsStr: (
      state,
      action: PayloadAction<CustomCohortsStrProps | undefined>
    ) => {
      state.customCohortsStr = JSON.stringify(action.payload);
    },
  },
});

/* Action(s) */
export const {
  setName,
  setEntity,
  setDateRestriction,
  setValue,
  setCustomCohortsStr,
} = customCohortsSlice.actions;

/* Reducer */
export default customCohortsSlice.reducer;
