/* React */
import React, { useEffect, useRef } from "react";

/* Ionic */
import { person } from "ionicons/icons";

/* React-Redux */
import { useDispatch } from "react-redux";

import {
  setOptions,
  clearOptions,
} from "src/redux/features/insights/insights-filter/insightsFilterOptionsSlice";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";
import { config, sankeyDiagramLayout } from "src/utils/config/chart-config";
import { createSankeyDiagramLayout } from "src/utils/helper/chart-helper";
import { capitalizeFirstLetter } from "src/utils/helper/string-transformation";

import {
  computeMaxDepth,
  createTreatmentLinesDeepestFlow,
} from "src/utils/chart/sankey-diagram";

/* Stylesheet */
import styles from "./SankeyDiagram.module.scss";

/* Interface(s) */
import { ChartType } from "src/shared/interfaces/charts/BaseChart";
import { SankeyDiagramProps } from "src/shared/interfaces/charts/SankeyDiagram";
import { ChartLabelProps } from "src/shared/interfaces/common/ChartLabel";

const SankeyDiagram: React.FC<{
  index: number;
  total: number;
  data: SankeyDiagramProps;
}> = ({ total, data }) => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement | null>(null);

  // sankeyDiagramData
  const sankeyDiagramData = {
    total: data.total,
    ...createSankeyDiagramLayout(data.sankey),
  };

  useEffect(() => {
    dispatch(setOptions(ChartType.SANKEY));

    return () => {
      dispatch(clearOptions());
    };
  }, [dispatch]);

  const deepestFlow: number = computeMaxDepth(data.sankey);

  const sankeyDiagram = (
    <div ref={ref}>
      <Plot
        className={styles.sankey_diagram}
        data={[sankeyDiagramData]}
        config={config}
        layout={{
          ...sankeyDiagramLayout,
          annotations: createTreatmentLinesDeepestFlow(deepestFlow),
        }}
        useResizeHandler={true}
      />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    label: {
      icon: person,
      name: "Patients",
      overall: total,
      total: sankeyDiagramData.total,
    },
    featureOrCohort: { name: capitalizeFirstLetter(sankeyDiagramData.name) },
    chart: sankeyDiagram,
  };

  return <ChartWrapper label={chartLabel}>{sankeyDiagram}</ChartWrapper>;
};

export default SankeyDiagram;
