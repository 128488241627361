/* React */
import React from "react";

/* Minimal-Pie-Chart */
import { PieChart } from "react-minimal-pie-chart";

/* Stylesheet */
import styles from "./MinimalPieChart.module.scss";

/* Interface */
import { LegendProps } from "src/shared/interfaces/charts/Legend";

const MinimalPieChart: React.FC<{ legend: LegendProps[] }> = ({ legend }) => {
  const total: number = legend?.reduce(
    (acc: number, item: LegendProps) => acc + item.total,
    0
  );

  return (
    <div className={styles.minimal_pie_chart}>
      <PieChart
        className={styles.pie_chart}
        startAngle={90}
        lineWidth={68}
        data={legend?.map((item: LegendProps) => {
          return {
            value: item.total,
            color: item.color,
          };
        })}
      />
      <div className={styles.label_container}>
        {legend?.map((item: LegendProps, index: number) => {
          const percentage: string = ((item.total / total) * 100).toFixed(2);
          return item.total ? (
            <div key={index} className={styles.label}>
              <div
                style={{ background: item.color }}
                className={styles.color}
              />
              <div className={styles.percentage}>{percentage}%</div>
            </div>
          ) : undefined;
        })}
      </div>
    </div>
  );
};

export default MinimalPieChart;
