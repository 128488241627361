/* treatmentPatternsThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Axios */
import axios from "axios";

/* Utils */
import axiosInterceptor from "src/utils/helper/axios-interceptor";

import {
  defaultCohort,
  defaultStartDate,
  defaultEndDate,
} from "src/utils/helper/insights-filter";

/* interface */
import { BaseChartProps } from "src/shared/interfaces/charts/BaseChart";
import { SankeyDiagramProps } from "src/shared/interfaces/charts/SankeyDiagram";
import { BaseChartDataRequest } from "src/shared/interfaces/api/ChartData";
import { TreatmentFilterRequest } from "src/shared/interfaces/api/Filters";
import { BaseErrorProps } from "src/shared/interfaces/common/Error";

interface DataItem {
  sankey: { type: string };
  response_table: { type: string };
  boxplot: { type: string }[];
}

export const fetchTreatmentPatterns = createAsyncThunk<
  BaseChartProps<SankeyDiagramProps[]>,
  BaseChartDataRequest<TreatmentFilterRequest>,
  { rejectValue: BaseErrorProps }
>(
  "treatmentPatterns/fetchTreatmentPatterns",
  async (
    params: BaseChartDataRequest<TreatmentFilterRequest>,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInterceptor.get("/data/treatment_patterns", {
        params: {
          main_condition: params.mainCondition,
          cohort: defaultCohort(params.filters.cohort),
          start_date: defaultStartDate(
            params.filters.startDate.value,
            params.filters.startDate.initialValue!
          ),
          end_date: defaultEndDate(
            params.filters.endDate.value,
            params.filters.endDate.initialValue!
          ),
          max_depth: params.filters.maxDepth,
          min_width: params.filters.minWidth,
        },
      });

      // const responseTable: [
      //   {
      //     responseTable: [

      //     ]
      //   }
      // ]

      // const responseTable: {
      //   response_table: [
      //     {
      //       timeline: "TL 1";
      //       Ongoing: 18;
      //       Unknown: 4;
      //     },
      //     {
      //       timeline: "TL 2";
      //       Ongoing: 18;
      //       Unknown: 4;
      //     }
      //   ];
      // };

      //   "response_table": {
      //     "name": "",
      //     "type": "table",
      //     "response_table": [
      //         {
      //             "timeline": "TL 1",
      //             "Ongoing": 18,
      //             "Unknown": 4
      //         },
      //         {
      //             "timeline": "TL 2",
      //             "Ongoing": 1,
      //             "Unknown": 0
      //         }
      //     ]
      // }

      const tmpResponse: BaseChartProps<SankeyDiagramProps[]> = {
        ...response.data,
        types: response.data.data.map(
          ({ sankey, response_table, boxplot }: DataItem) => [
            sankey.type,
            response_table.type,
            ...boxplot.map(({ type }) => type),
          ]
        ),

        data: response.data.data.map((data: SankeyDiagramProps) => ({
          ...data,
          name: data.name ? data.name : "Full sample",
          sankey: {
            ...data.sankey,
            name: data.name ? data.name : "Full sample",
          },
        })),
      };

      return tmpResponse;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message: string = error.response?.data.detail;
        return rejectWithValue({ showError: true, message: message });
      }
      return rejectWithValue({
        showError: true,
        message: "An unexpected error occured",
      });
    }
  }
);
