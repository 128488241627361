/* React */
import React from "react";

/* Ionic */
import { IonItem } from "@ionic/react";

/* Stylesheet */
import styles from "./ItemsContainer.module.scss";

/* Interface */
interface ItemsContainerProps<G extends { name: string }> {
  items: G[];
  handleShowItems: (value: boolean) => void;
  onClick: (item: G) => void;
}

// extends ensures that the generic type T has a property called name
const ItemsContainer = <G extends { name: string }>({
  items,
  handleShowItems,
  onClick,
}: ItemsContainerProps<G>) => {
  return (
    <div className={styles.items_container}>
      {items?.map((item: G, index: number) => {
        return (
          <IonItem
            key={index}
            className={styles.item}
            lines="none"
            button
            onClick={() => {
              onClick(item);
              handleShowItems(false);
            }}
          >
            <div className={styles.name}>{item.name}</div>
          </IonItem>
        );
      })}
    </div>
  );
};

export default ItemsContainer;
