/* React */
import React from "react";

/* Ionic */
import { IonSegment } from "@ionic/react";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setValue } from "src/redux/features/custom-cohorts/customCohortsSlice";

/* Component */
import SegmentButton from "src/components/insights/custom-cohorts/query-builder/segment-button/SegmentButton";

/* Stylesheet */
import styles from "./BooleanContainer.module.scss";

/* Interface */
interface BooleanContainerProps {
  options: boolean[];
  value: boolean;
}

const BooleanContainer: React.FC<BooleanContainerProps> = ({
  options,
  value,
}) => {
  const dispatch = useDispatch();

  return (
    <IonSegment className={styles.boolean_container}>
      {options.map((option: boolean, index: number) => {
        return (
          <SegmentButton
            key={index}
            value={value ? "True" : "False"}
            item={option ? "True" : "False"}
            onClick={() => dispatch(setValue(option))}
          />
        );
      })}
    </IonSegment>
  );
};

export default BooleanContainer;
