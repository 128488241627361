/* React */
import React from "react";

/* Ionic */
import { qrCode as qrCodeIcon } from "ionicons/icons";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";

/* React-Hook-Form */
import { useForm } from "react-hook-form";

/* Thunk */
import { loginWithOTP } from "src/redux/thunks/auth/authThunk";

/* Utils */
import useCredentials from "src/utils/hooks/useCredentials";

/* Components */
import QRCodeContainer from "src/components/login/two-factor-authentication/qr-code-container/QRCodeContainer";
import PrimaryInput from "src/components/shared/input/primary-input/PrimaryInput";
import PrimaryButton from "src/components/shared/buttons/primary-button/PrimaryButton";

/* Stylesheet */
import styles from "./SecondFactor.module.scss";

/* Interface */
import { TwoFaLoginRequest } from "src/shared/interfaces/api/Login";

const SecondFactor: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // authState
  const authState = useSelector((state: RootState) => state.auth);

  const { information } = authState;

  const {
    register,
    formState: { errors },
    setValue,
    trigger,
    clearErrors,
    handleSubmit,
  } = useForm();

  const initialCredentials: TwoFaLoginRequest = {
    usernameOrEmail: information.credentials.usernameOrEmail,
    password: information.credentials.password,
    code: "",
  };

  const { credentials, handleCredentials } = useCredentials({
    initialCredentials,
    setValue,
    trigger,
  });

  const onSubmit = () => {
    dispatch(loginWithOTP(credentials));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      {information?.qrCodeRequired ? (
        <QRCodeContainer code={information?.qrCode} />
      ) : (
        <div className={styles.info_message}>
          Enter the <span>code</span> provided by your authenticator app
        </div>
      )}
      <PrimaryInput
        register={register("code", { required: "Code required" })}
        errors={errors}
        clearErrors={clearErrors}
        type={"number"}
        name={"code"}
        placeholder={"Enter code"}
        value={credentials.code}
        icon={qrCodeIcon}
        onChange={handleCredentials}
      />
      <PrimaryButton
        type={"submit"}
        ariaLabel={"Login"}
        label={"Login"}
        disabled={credentials.code?.length === 0}
      />
    </form>
  );
};

export default SecondFactor;
