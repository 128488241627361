/* React */
import React, { useEffect } from "react";

/* React-Redux */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";

/* Thunk */
import { fetchDescriptiveStatistics } from "src/redux/thunks/data/descriptiveStatisticsThunk";

/* Utils */
import useChartLoadingScreen from "src/utils/hooks/useChartLoadingScreen";
import ChartLayoutWrapper from "src/utils/wrapper/chart-layout-wrapper/ChartLayoutWrapper";
import { basicLayout } from "src/utils/layout/basicLayout";

/* Component */
import ToastMessage from "src/components/shared/toast-message/ToastMessage";

/* Interface(s) */
import { LayoutProps } from "src/shared/interfaces/common/Layout";
import { ErrorType } from "src/shared/interfaces/common/Error";
import { clearError } from "src/redux/features/auth/authSlice";

const DescriptiveStatistics: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const mainConditionState = useSelector(
    (state: RootState) => state.mainCondition
  );

  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const descriptiveStatisticsState = useSelector(
    (state: RootState) => state.descriptiveStatistics
  );
  const { data, isLoading, isUpdating, error } = descriptiveStatisticsState;

  const customCohortsState = useSelector(
    (state: RootState) => state.customCohorts
  );
  const { customCohortsStr } = customCohortsState;

  useChartLoadingScreen(isLoading!, isUpdating!);

  useEffect(() => {
    if (mainConditionState.value) {
      dispatch(
        fetchDescriptiveStatistics({
          mainCondition: mainConditionState.value,
          filters: {
            feature: insightsFilterState.features.value!,
            cohort: insightsFilterState.cohorts.value!,
            startDate: insightsFilterState.firstYear,
            endDate: insightsFilterState.lastYear,
            customCohortsStr: customCohortsStr,
          },
        })
      );
    }
    return () => {
      dispatch(clearError());
    };
  }, [
    dispatch,
    mainConditionState.value,
    insightsFilterState,
    customCohortsStr,
  ]);

  const types: string[] = data?.types as string[];

  const layout: LayoutProps = basicLayout(types, true, data!);

  return (
    <React.Fragment>
      <ChartLayoutWrapper layout={layout} />
      {error ? (
        <ToastMessage
          isOpen={error.showError}
          message={error.message!}
          level={ErrorType.ERROR}
        />
      ) : undefined}
    </React.Fragment>
  );
};

export default DescriptiveStatistics;
