/* providerSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Thunk*/
import { providerInfo } from "src/redux/thunks/auth/providerThunk";

/* Interfaces */
import { BaseSliceProps } from "src/shared/interfaces/common/Slice";
import { BaseErrorProps } from "src/shared/interfaces/common/Error";
import { ProviderProps } from "src/shared/interfaces/common/Provider";

const initialState: BaseSliceProps<ProviderProps> = {
  data: {
    google: {
      clientId: "",
      redirectUri: "",
    },
  },
  isLoading: false,
  error: undefined,
};

export const providerSlice = createSlice({
  name: "provider",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(providerInfo.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      providerInfo.rejected,
      (state, action: PayloadAction<BaseErrorProps | undefined>) => {
        state.isLoading = false;
        state.error = {
          showError: action.payload!.showError,
          message: action.payload!.message,
        };
      }
    );
    builder.addCase(
      providerInfo.fulfilled,
      (state, action: PayloadAction<ProviderProps>) => {
        state.isLoading = false;
        state.data = action.payload;
      }
    );
  },
});

/* Action(s) */

/* Reducer */
export default providerSlice.reducer;
