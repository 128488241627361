/* React */
import React from "react";

/* Ionic */
import { IonRange } from "@ionic/react";

/* Components */
import LabelPrimary from "src/components/shared/labels/label-primary/LabelPrimary";
import ValueContainer from "src/components/shared/range-slider/value-container/ValueContainer";

/* Stylesheet */
import styles from "./RangeSlider.module.scss";

/* Interface */
interface RangeSliderProps {
  label?: string;
  min: number;
  max: number;
  ticks?: boolean;
  snaps?: boolean;
  pin?: boolean;
  dualKnobs?: boolean;
  value: number;
  onChange: (value: number) => void;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  label,
  min,
  max,
  ticks,
  snaps,
  pin,
  dualKnobs,
  value,
  onChange,
}) => {
  return (
    <div className={styles.range_slider}>
      <LabelPrimary label={label!} />
      <IonRange
        className={"range"}
        min={min}
        max={max}
        ticks={ticks}
        snaps={snaps}
        value={value}
        pin={pin}
        pinFormatter={(value) => value}
        dualKnobs={dualKnobs}
        onIonChange={(e) => onChange(e.detail.value as number)}
      />
      <ValueContainer min={min} max={max} />
    </div>
  );
};

export default RangeSlider;
