/* React */
import React from "react";

/* Ionic */
import { IonIcon } from "@ionic/react";
import { statsChart } from "ionicons/icons";

/* Stylesheet */
import styles from "./StatsLabel.module.scss";

/* Interfaces */
interface StatsLabelProps {
  label: string;
}

const StatsLabel: React.FC<StatsLabelProps> = ({ label }) => {
  return (
    <div className={styles.stats_label}>
      <IonIcon className={styles.icon} icon={statsChart} />
      <div className={styles.label}>{label}</div>
    </div>
  );
};

export default StatsLabel;
