/* chart-helper.ts */

/* Utils */
import {
  colorMapper,
  treatmentColorMapper,
} from "src/utils/helper/color-mapper";

/* Interface(s) */
import { ChartType } from "src/shared/interfaces/charts/BaseChart";

import {
  SankeyBoxplotProps,
  SankeyProps,
} from "src/shared/interfaces/charts/SankeyDiagram";

import {
  CIProps,
  SurvivalPlotProps,
} from "src/shared/interfaces/charts/SurvivalPlot";
import { size } from "lodash";

export const createHoverLabel = (
  index: number,
  colorMapper: (index: number) => string
) => {
  return {
    bgcolor: colorMapper(index),
    font: {
      color: "#ffffff",
      size: 14,
    },
    bordercolor: "transparent",
  };
};

/**
 * computeBins() computes the bin range (groups) for histogram
 * Math.floor rounds x value to the nearest integer less than or equal to x
 * @param x: number
 * @returns string
 **/
const computeBins = (x: number): string => {
  const binWidth: number = 5;
  /*
   * Example: x / binWidth => 12 / 5 = 2.4
   * Math.floor(2.4) = 2
   * 2 * 5 = 10
   * The bin starts at 10
   */
  const binStart: number = Math.floor(x / binWidth) * binWidth;
  const binEnd: number = binStart + 5;
  return `${binStart} - ${binEnd}`;
};

/**
 * Method createBoxplotLabel() is used to create labels for boxplot
 * If there are more than one point, there are multiple labels (min, lower, q1, median, q3, upper, max) -
 * otherwise, only one label (outlier) is returned
 *
 * @param index: number
 * @param length: number
 * @param x: number
 * @returns { [key: string]: string | number }
 */
const createBoxplotLabel = (
  index: number,
  length: number,
  orientation: "h" | "v",
  x: number
): { [key: string]: string | number } => {
  if (length > 1) {
    if (orientation === "h") {
      const boxplotLabels: string[] = [
        "min",
        "lower",
        "q1",
        "median",
        "q3",
        "upper",
        "max",
      ];
      return {
        [boxplotLabels[index]]: x.toFixed(2),
      };
    } else {
      const boxplotLabels: string[] = [
        "max",
        "upper",
        "q3",
        "median",
        "q1",
        "lower",
        "min",
      ];
      return {
        [boxplotLabels[index]]: x.toFixed(2),
      };
    }
  } else {
    return {
      outlier: x.toFixed(2),
    };
  }
};

export const createLabel = (
  index: number,
  type: ChartType,
  orientation: "h" | "v",
  length: number,
  x: number | string,
  y: number
) => {
  switch (type) {
    case ChartType.HISTOGRAM:
      return {
        group: computeBins(x as number),
        value: y,
      };
    case ChartType.BOX:
      return createBoxplotLabel(
        index,
        length,
        orientation,
        orientation === "h" ? (x as number) : y
      );
    case ChartType.SCATTER:
      return {
        time: `${Math.round(x as number)} mth`,
        event: y.toFixed(4),
      };
    default:
      return {
        group: x,
        value: y,
      };
  }
};

export const createLayout = (index: number) => {
  return {
    marker: {
      color: colorMapper(index),
    },
    hoverinfo: "none",
  };
};

/* Boxplot */
export const createBoxplotLayout = (index: number) => {
  return {
    orientation: "h",
    marker: {
      color: colorMapper(index),
    },
    hoverinfo: "none",
  };
};

/* Survival Plot */
const transformXToMonths = (x: number[]) => {
  return x.map((x) => x / 30.44).filter((x) => x >= 0);
};

export const createSurvivalPlotLayout = (
  index: number,
  data: SurvivalPlotProps,
  showConfidenceIntervals: boolean
) => {
  return {
    ...data,
    marker: { color: colorMapper(index) },
    trace: {
      ...data.trace,
      type: ChartType.SCATTER,
      name: data.name,
      x: transformXToMonths(data.trace.x),
      y: data.trace.y,
      mode: "lines",
      line: { shape: "hv" },
      marker: { color: colorMapper(index) },
      hoverinfo: "none",
    },
    censored: {
      ...data.censored,
      type: ChartType.SCATTER,
      name: data.name,
      x: transformXToMonths(data.censored.x),
      y: data.censored.y,
      mode: "text",
      text: "|",
      textfont: {
        color: colorMapper(index),
      },
      marker: { color: colorMapper(index) },
      hoverinfo: "none",
    },
    ciUpper: showConfidenceIntervals
      ? {
          ...data.ciUpper,
          name: "Upper",
          x: transformXToMonths((data.ciUpper as CIProps).x),
          y: (data.ciUpper as CIProps)?.y,
          mode: "lines",
          line: { width: 0 },
          fill: "tonexty",
          fillcolor: `${colorMapper(index)}25`,
          hoverinfo: "skip",
        }
      : {},
    ciLower: showConfidenceIntervals
      ? {
          ...data.ciLower,
          name: "Lower",
          x: transformXToMonths((data.ciLower as CIProps).x),
          y: (data.ciLower as CIProps)?.y,
          mode: "lines",
          marker: { color: "transparent" },
          fill: "tonexty",
          fillcolor: `${colorMapper(index)}25`,
          hoverinfo: "skip",
        }
      : {},
  };
};

/* Sankey Diagram */
export const createSankeyDiagramLayout = (data: SankeyProps) => {
  return {
    ...data,
    hoverinfo: "none",

    node: {
      ...data.node,
      pointerevents: "none",
      color: data.node.label.map((_, index) => {
        const color = treatmentColorMapper(index, data.node.label);
        return color;
      }),
      line: {
        color: "transparent",
      },
      hoverinfo: "none",
    },
    link: {
      ...data.link,
      color: data.link.source.map((sourceIndex) => {
        const color = treatmentColorMapper(sourceIndex, data.node.label);
        return `${color}AA`;
      }),
    },
  };
};

/* Sankey Boxplot */
export const createSankeyBoxplotLayout = (data: SankeyBoxplotProps) => {
  return {
    ...data,
    data: [
      ...data.data.map((data, index) => {
        return {
          ...data,
          orientation: "v",
          marker: {
            color: treatmentColorMapper(index, data.name),
          },
          hoverinfo: "none",
        };
      }),
    ],
  };
};
