/* React */
import React from "react";

/* Ionic */
import { IonInput, IonIcon } from "@ionic/react";

/* React-Hook-Form */
import {
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
} from "react-hook-form";

/* Component */
import LabelPrimary from "src/components/shared/labels/label-primary/LabelPrimary";

/* Stylesheet */
import styles from "./PrimaryInput.module.scss";

/* Interface */
interface PrimaryInputProps {
  register?: ReturnType<UseFormRegister<FieldValues>>;
  errors?: FieldErrors<FieldValues>;
  clearErrors?: UseFormClearErrors<FieldValues>;
  type: "text" | "password" | "number";
  name: string;
  label?: string;
  placeholder: string;
  value: string | number;
  icon?: string;
  disabled?: boolean;
  onChange: (name: string, value: string) => void;
}

const PrimaryInput: React.FC<PrimaryInputProps> = ({
  register,
  errors,
  clearErrors,
  type,
  name,
  label,
  placeholder,
  value,
  icon,
  disabled,
  onChange,
}) => {
  return (
    <React.Fragment>
      {label ? (
        <LabelPrimary
          scssProps={{ color: "var(--ion-color-text-primary)" }}
          label={label}
        />
      ) : undefined}
      <div
        style={{
          margin: errors?.[name] ? "12px 0px 12px 0px" : "18px 0px 18px 0px",
        }}
        className={styles.primary_input}
      >
        {icon ? (
          <div className={styles.icon_container}>
            <IonIcon
              style={{
                color: errors?.[name] ? "var(--ion-color-error)" : undefined,
              }}
              className={styles.icon}
              icon={icon}
            />
          </div>
        ) : undefined}
        <IonInput
          {...register}
          key={name}
          style={{
            "--padding-start": !icon ? "21px" : undefined,
            color: disabled
              ? "var(--ion-color-disabled)"
              : errors?.[name]
              ? "var(--ion-color-error)"
              : undefined,
          }}
          autocomplete="off"
          aria-label={placeholder}
          className={`${styles.input} input`}
          type={type}
          placeholder={placeholder}
          value={value}
          disabled={disabled}
          onIonInput={(e: CustomEvent) => {
            const inputValue = e.detail?.value || "";
            onChange(name, inputValue);
            clearErrors?.(name);
          }}
          onIonChange={() => clearErrors?.(name)}
        />
      </div>
      {errors?.[name] && (
        <div className={styles.error_message}>
          {errors[name]?.message as React.ReactNode}
        </div>
      )}
    </React.Fragment>
  );
};

export default PrimaryInput;
