/* React */
import React, { useRef, useEffect, useState } from "react";

/* Ionic */
import { IonItem, IonIcon } from "@ionic/react";
import { chevronDown, chevronUp } from "ionicons/icons";

/* Hook */
import useClickOutsideHandler from "src/utils/hooks/useClickOutsideHandler";

/* Utils */
import { capitalizeFirstLetter } from "src/utils/helper/string-transformation";

/* Components */
import SelectItemContainer from "src/components/shared/select-container/select-item-container/SelectItemContainer";
import LabelPrimary from "src/components/shared/labels/label-primary/LabelPrimary";

/* Stylesheet */
import styles from "./SelectContainer.module.scss";

/* Interface */
import { FilterProps } from "src/shared/interfaces/common/InsightsFilter";

const SelectContainer: React.FC<{
  itemKey: string;
  item: FilterProps<string>;
  handleValue: (key: string, value: string) => void;
  disabled?: boolean;
}> = ({ itemKey, item, handleValue, disabled }) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  // showValues
  const [showValues, setShowValues] = useState<boolean>(false);

  // searchTerm
  const [searchTerm, setSearchTerm] = useState<string>("");

  const handleShowValues = (value: boolean) => {
    setShowValues(value);
  };

  useClickOutsideHandler({ ref: dropdownRef, onClick: handleShowValues });

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      setSearchTerm((prev: string) => prev + event.key.toLowerCase());

      // Clear the previous timeout if it exists
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }

      timeoutRef.current = setTimeout(() => {
        setSearchTerm("");
      }, 3000);
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
      // Clear timeout on cleanup
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // Remove already selected items
  const itemFilter = (value: string, items: string[]) => {
    return items.filter((item) => item !== value);
  };

  // Filter items based on search term
  const itemsFiltered = item.items?.filter((item) => {
    return item.toLowerCase().startsWith(searchTerm);
  });

  return (
    <div
      ref={dropdownRef}
      className={`${styles.select_container} ${
        showValues ? styles.selectd : undefined
      }`}
    >
      <LabelPrimary
        scssProps={{ margin: "0px 0px 21px 8px" }}
        label={capitalizeFirstLetter(itemKey)}
      />
      <div className={styles.wrapper_container}>
        <IonItem
          style={{
            "--background": showValues
              ? "var(--ion-gradient-secondary)"
              : undefined,
            borderRadius: showValues ? "8px 8px 0px 0px" : "42px",
          }}
          className={styles.select_item}
          lines="none"
          button
          onClick={() => handleShowValues(!showValues)}
          disabled={disabled}
        >
          <div className={`${styles.label}`}>{item?.value}</div>
          <IonIcon
            style={{ color: showValues ? "#ffffff" : undefined }}
            className={styles.icon}
            icon={showValues ? chevronUp : chevronDown}
            slot="end"
          />
        </IonItem>
        {showValues ? (
          <SelectItemContainer
            itemKey={itemKey!}
            items={itemFilter(item.value!, itemsFiltered!)}
            handleShowValues={handleShowValues}
            handleValue={handleValue}
          />
        ) : undefined}
      </div>
    </div>
  );
};

export default SelectContainer;
