/* NavItems.ts */

/* Ionic */
import { home, analytics, settings, help } from "ionicons/icons";

/* Font-Awesome */
import {
  faChartColumn,
  faLineChart,
  faChartGantt,
} from "@fortawesome/free-solid-svg-icons";

/* Interface */
import { NavItemProps } from "src/shared/interfaces/common/NavItem";

/* Main Menu */
export const MAIN_MENU_NAV_ITEMS: NavItemProps[] = [
  {
    name: "Welcome",
    icon: home,
    path: "/",
  },
  {
    name: "Insights",
    icon: analytics,
    path: "/insights",
  },
];

/* Insights Menu */
export const INSIGHTS_MENU_NAV_ITEMS: NavItemProps[] = [
  {
    name: "Descriptive Statistics",
    icon: faChartColumn,
    path: "/insights/descriptive-statistics",
  },
  {
    name: "Survival Analysis",
    icon: faLineChart,
    path: "/insights/survival-analysis",
  },
  {
    name: "Treatment Patterns",
    icon: faChartGantt,
    path: "/insights/treatment-patterns",
  },
];

/* User Menu */
export const USER_MENU_NAV_ITEMS: NavItemProps[] = [
  {
    name: "Settings",
    icon: settings,
    path: "/settings",
  },
  {
    name: "Help & Support",
    icon: help,
    path: "/help-support",
  },
];
