/* React */
import React, { useRef } from "react";

/* Ionic */
import { person } from "ionicons/icons";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import useResizeObserver from "src/utils/hooks/useResizeObserver";
import useBasicChartTooltip from "src/utils/hooks/tooltip/useBasicChartTooltip";
import { config, histogramLayout } from "src/utils/config/chart-config";
import { createLayout } from "src/utils/helper/chart-helper";
import { createLegendInformation } from "src/utils/helper/legend";

/* Component(s) */
import ChartTooltip from "src/components/insights/charts/chart-tooltip/ChartTooltip";
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Stylesheet */
import styles from "./Histogram.module.scss";

/* Interface(s) */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interfaces/charts/BaseChart";

import { HistogramProps } from "src/shared/interfaces/charts/Histogram";
import { ChartLabelProps } from "src/shared/interfaces/common/ChartLabel";

const Histogram: React.FC<{
  index: number;
  stacked: boolean;
  data: BaseChartProps<HistogramProps[]>;
}> = ({ index, stacked, data }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const offset = useResizeObserver(ref);
  const { tooltip, handleOnHover, handleOnUnhover } =
    useBasicChartTooltip(offset);

  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const { features } = insightsFilterState;

  const histogramData: HistogramProps[] = data.data
    .filter((data: HistogramProps) => data.type === ChartType.HISTOGRAM)
    .map((data: HistogramProps, index: number) => {
      return {
        ...data,
        ...createLayout(index),
      };
    });

  const histogram = (
    <div ref={ref} data-testid="histogram">
      <Plot
        className={styles.histogram}
        data={stacked ? histogramData : [histogramData[index]]}
        config={config}
        layout={{
          ...histogramLayout,
          xaxis: {
            ...histogramLayout.xaxis,
          },
        }}
        useResizeHandler={true}
        onHover={(event) => handleOnHover(event)}
        onUnhover={handleOnUnhover}
      />
      <ChartTooltip tooltip={tooltip} />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    label: { icon: person, total: data.total!, name: "Patients" },
    featureOrCohort: { name: features.value! },
    chart: histogram,
    legend: createLegendInformation(histogramData),
  };

  return <ChartWrapper label={chartLabel}>{histogram}</ChartWrapper>;
};

export default Histogram;
