/* React */
import React, { useEffect } from "react";

/* React-Router-Dom */
import { Redirect, Route, useRouteMatch, useLocation } from "react-router-dom";

/* Ionic */
import { IonRouterOutlet } from "@ionic/react";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setDisabled } from "src/redux/features/insights/insights-filter/insightsFilterMenuSlice";
import { setPageTitle } from "src/redux/features/page-title/pageTitleSlice";

/* Utils */
import PageWrapper from "src/utils/wrapper/page-wrapper/PageWrapper";
import InsightsPageWrapper from "src/utils/wrapper/insights-page-wrapper/InsightsPageWrapper";

/* Components */
import LoadingScreen from "src/components/shared/loading-screen/LoadingScreen";
import InsightsFilterMenu from "src/components/menus/insights/insights-filter-menu/InsightsFilterMenu";
import CustomCohorts from "src/components/insights/custom-cohorts/CustomCohorts";

/* Pages */
import DescriptiveStatistics from "src/pages/insights/descriptive-statistics/DescriptiveStatistics";
import SurvivalAnalysis from "src/pages/insights/survival-analysis/SurvivalAnalysis";
import TreatmentPatterns from "src/pages/insights/treatment-patterns/TreatmentPatterns";

const Insights: React.FC = () => {
  const dispatch = useDispatch();
  const path = useRouteMatch();
  const location = useLocation();

  useEffect(() => {
    const transformString = (input: string) => {
      if (location.pathname === "/") {
        return undefined;
      }

      const path = input.replace(/^\/insights\//, "");
      const words = path.replace(/-/g, " ").split(" ");
      const capitalizedWords = words.map(
        (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      );
      return capitalizedWords.join(" ");
    };

    dispatch(setPageTitle({ title: transformString(location.pathname) }));
    dispatch(setDisabled(false));

    return () => {
      dispatch(setDisabled(true));
      dispatch(setPageTitle({ title: undefined }));
    };
  }, [dispatch, location]);

  return (
    <PageWrapper>
      <InsightsFilterMenu />
      <LoadingScreen />
      <CustomCohorts />
      <IonRouterOutlet id="filter-content">
        <InsightsPageWrapper>
          <Route
            exact
            path={`${path.url}/descriptive-statistics`}
            component={DescriptiveStatistics}
          />
          <Route
            exact
            path={`${path.url}/survival-analysis`}
            component={SurvivalAnalysis}
          />
          <Route
            exact
            path={`${path.url}/treatment-patterns`}
            component={TreatmentPatterns}
          />
          <Route exact path={`${path.url}`}>
            <Redirect to={`${path.url}/descriptive-statistics`} />
          </Route>
        </InsightsPageWrapper>
      </IonRouterOutlet>
    </PageWrapper>
  );
};

export default Insights;
