/* React */
import React from "react";

/* Utils */
import { capitalizeFirstLetter } from "src/utils/helper/string-transformation";

/* Stylesheet */
import styles from "./Hovertext.module.scss";

/* Interface(s) */
import { ContentProps } from "src/shared/interfaces/charts/ChartTooltip";

interface HovertextProps {
  index: number;
  content: ContentProps;
}

const Hovertext: React.FC<HovertextProps> = ({ content }) => {
  const { label, hovertext } = content;

  return hovertext ? (
    <div className={styles.hovertext}>
      {Object.keys(hovertext).map((key: string, index: number) => {
        return (
          <div
            key={index}
            style={{ margin: label ? "12px 0px 0px 0px" : "12px 0px 12px 0px" }}
            className={styles.wrapper_container}
          >
            <div className={styles.key}>{capitalizeFirstLetter(key)}: </div>
            <div className={styles.value}>{hovertext[key]}</div>
          </div>
        );
      })}
    </div>
  ) : undefined;
};

export default Hovertext;
