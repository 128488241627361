/* loadingScreenSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Interface */
import { LoadingScreenProps } from "src/shared/interfaces/common/LoadingScreen";

const initialState: LoadingScreenProps = {
  isLoading: false,
  isUpdating: false,
  showContent: false,
};

export const loadingScreenSlice = createSlice({
  name: "loadingScreen",
  initialState: initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsUpdating: (state, action: PayloadAction<boolean>) => {
      state.isUpdating = action.payload;
    },
    setShowContent: (state, action: PayloadAction<boolean>) => {
      state.showContent = action.payload;
    },
  },
});

/* Actions */
export const { setIsLoading, setIsUpdating, setShowContent } =
  loadingScreenSlice.actions;

/* Reducer */
export default loadingScreenSlice.reducer;
