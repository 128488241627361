/* React */
import React from "react";

/* Ionic */
import { IonIcon, IonSegmentButton } from "@ionic/react";
import { ellipseOutline, radioButtonOnOutline } from "ionicons/icons";

/* Stylesheet */
import styles from "./SegmentButton.module.scss";

/* Interface */
import { ValueType } from "src/shared/interfaces/api/CustomCohorts";

interface SegmentButtonProps {
  item: string;
  value: ValueType;
  icon?: string;
  disabled?: boolean;
  onClick: () => void;
}

const SegmentButton: React.FC<SegmentButtonProps> = ({
  item,
  value,
  icon,
  disabled,
  onClick,
}) => {
  const activeItem: boolean = item === value;
  return (
    <IonSegmentButton
      style={{
        color: activeItem
          ? "var(--ion-color-primary)"
          : "var(--ion-color-text-tertiary)",
        border: activeItem ? "1px solid rgba(70,155,215,0.75)" : undefined,
        background: "var(--ion-background-secondary)",
      }}
      className={styles.segment_button}
      disabled={disabled}
      onClick={onClick}
    >
      <div className={styles.wrapper_container}>
        {icon ? (
          <IonIcon className={styles.icon} icon={icon} slot="start" />
        ) : (
          <IonIcon
            className={styles.icon}
            icon={activeItem ? radioButtonOnOutline : ellipseOutline}
          />
        )}
        {item}
      </div>
    </IonSegmentButton>
  );
};

export default SegmentButton;
