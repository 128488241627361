/* React */
import React, { useState, useEffect } from "react";

/* Swiper */
import { Swiper, SwiperSlide } from "swiper/react";

/* Utils */
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Components */
import TableSlide from "src/components/shared/table/table-slide/TableSlide";
import SwiperControls from "src/components/shared/swiper/swiper-controls/SwiperControls";
// import TableSettings from "src/components/shared/swiper/table-settings/TableSettings";

/* Stylesheet */
import "swiper/scss";
import "swiper/css/pagination";

/* Interface */
import { TableProps } from "src/shared/interfaces/charts/Table";

const COLUMNS_PER_PAGE = 5;

const Table = <T,>({
  index,
  dataKey,
  stacked,
  data,
}: {
  index: number;
  dataKey: string;
  stacked: boolean;
  data: TableProps<T>[];
}) => {
  // activeIndex
  const [activeIndex, setActiveIndex] = useState<number>(0);
  // currentPage
  const [currentPage, setCurrentPage] = useState<number>(0);

  const startIndex = currentPage * COLUMNS_PER_PAGE;
  const endIndex = startIndex + COLUMNS_PER_PAGE;

  useEffect(() => {
    // set currentPage to 0 when activeIndex changes
    setCurrentPage(0);
  }, [activeIndex]);

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (
      currentPage <
      Math.ceil(data[activeIndex][dataKey].length / COLUMNS_PER_PAGE) - 1
    ) {
      setCurrentPage(currentPage + 1);
    }
  };

  const tableIndex: number = stacked ? activeIndex : index;

  return (
    <ChartWrapper
      scssProps={{
        padding: "0px",
        height: "auto",
      }}
    >
      <Swiper onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}>
        {/* if stacked, tables of cohorts will be shown as multiple slides */}
        {stacked ? (
          data.map((data, index) => {
            return (
              <SwiperSlide key={index}>
                <TableSlide
                  data={data[dataKey]}
                  startIndex={startIndex}
                  endIndex={endIndex}
                />
              </SwiperSlide>
            );
          })
        ) : (
          <SwiperSlide key={index}>
            <TableSlide
              data={data[index][dataKey]}
              startIndex={startIndex}
              endIndex={endIndex}
            />
          </SwiperSlide>
        )}
        <SwiperControls
          scssProps={{
            padding: "14px 21px 14px 21px",
          }}
          stacked={stacked}
          pagination={true}
          length={data.length}
          activeIndex={activeIndex}
          currentPage={currentPage + 1}
          name={data[tableIndex].name}
          disabled={{
            prevDisabled: currentPage === 0,
            nextDisabled:
              currentPage ===
              Math.ceil(data[activeIndex][dataKey]?.length / COLUMNS_PER_PAGE) -
                1,
          }}
          handlePrevPage={handlePrevPage}
          handleNextPage={handleNextPage}
        />
      </Swiper>
    </ChartWrapper>
  );
};

export default Table;
