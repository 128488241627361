/* survivalAnalysisThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Axios */
import axios from "axios";

/* Utils */
import axiosInterceptor from "src/utils/helper/axios-interceptor";

import {
  defaultCohort,
  defaultStartDate,
  defaultEndDate,
} from "src/utils/helper/insights-filter";

import { convertKeysToCamelCase } from "src/utils/helper/string-transformation";

/* Interfaces */
import { BaseChartProps } from "src/shared/interfaces/charts/BaseChart";

import {
  EventTableProps,
  SurvivalPlotProps,
} from "src/shared/interfaces/charts/SurvivalPlot";

import { SurvivalAnalysisRequest } from "src/shared/interfaces/api/ChartData";
import { BaseErrorProps } from "src/shared/interfaces/common/Error";

export const fetchSurvivalAnalysis = createAsyncThunk<
  BaseChartProps<(SurvivalPlotProps | EventTableProps)[]>,
  SurvivalAnalysisRequest,
  { rejectValue: BaseErrorProps }
>(
  "survivalAnalysis/fetchSurvivalAnalysis",
  async (params: SurvivalAnalysisRequest, { rejectWithValue }) => {
    try {
      const response = await axiosInterceptor.get("/data/survival_data", {
        params: {
          main_condition: params.mainCondition,
          cohort: defaultCohort(params.filters.cohort),
          start_date: defaultStartDate(
            params.filters.startDate.value!,
            params.filters.startDate.initialValue!
          ),
          end_date: defaultEndDate(
            params.filters.endDate.value!,
            params.filters.endDate.initialValue!
          ),
          survival_analysis_start_point: params.startPoint,
        },
      });

      const tmpResponse: BaseChartProps<
        (SurvivalPlotProps | EventTableProps)[]
      > = {
        ...response.data,
        types: response.data.data.map((data: { type: string }) => data.type),
        data: convertKeysToCamelCase(response.data.data).map(
          (data: SurvivalPlotProps | EventTableProps) => ({
            ...data,
            name: data.name ? data.name : "Full sample",
          })
        ),
      };

      return tmpResponse;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message: string = error.response?.data.detail;
        return rejectWithValue({ showError: true, message: message });
      }
      return rejectWithValue({
        showError: true,
        message: "An unexpected error occured",
      });
    }
  }
);

export const fetchStartPoints = createAsyncThunk(
  "survivalAnalysis/fetchStartPoints",
  async () => {
    const response = await axiosInterceptor.get(
      "/options/survival_analysis_start_points"
    );
    return {
      items: response.data,
    };
  }
);
