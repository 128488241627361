/* boxplot.ts */

/* Interface */
import { SankeyBoxplotProps } from "src/shared/interfaces/charts/SankeyDiagram";

/**
 * @description
 * Boxplots, where length of data.y is 0, of all inner boxplots are removed
 *
 * @param data {SankeyBoxplotProps[]}
 * @returns {SankeyBoxplotProps[]}
 */
export const removeEmptyBoxplot = (
  data: SankeyBoxplotProps[]
): SankeyBoxplotProps[] => {
  const tmpData = data.filter((data: SankeyBoxplotProps) => {
    return !data.data.every((data) => {
      return data.y.length === 0;
    });
  });
  return tmpData;
};
