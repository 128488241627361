/* React */
import React from "react";

/* Ionic */
import { IonSegment } from "@ionic/react";
import { calendar } from "ionicons/icons";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setDateRestriction } from "src/redux/features/custom-cohorts/customCohortsSlice";

/* Constants */
import { DATE_RESTRICTION } from "src/shared/constants/custom-cohorts/DateRestriction";

/* Components */
import LabelPrimary from "src/components/shared/labels/label-primary/LabelPrimary";
import SegmentButton from "src/components/insights/custom-cohorts/query-builder/segment-button/SegmentButton";

/* Stylesheet */
import styles from "./DateRestriction.module.scss";

/* Interface */
import { DateRestrictionType } from "src/shared/interfaces/custom-cohorts/DateRestriction";

const DateRestriction: React.FC<{ restriction: DateRestrictionType }> = ({
  restriction,
}) => {
  const dispatch = useDispatch();
  const dateRestriction: DateRestrictionType[] = DATE_RESTRICTION;

  return (
    <React.Fragment>
      <LabelPrimary
        scssProps={{
          margin: "42px 0px 16px 16px",
          color: "var(--ion-color-text-primary)",
        }}
        label={"Date restriction"}
      />
      <IonSegment className={styles.segment_container}>
        {dateRestriction.map((item: DateRestrictionType, index: number) => {
          return (
            <SegmentButton
              key={index}
              item={item}
              value={restriction}
              onClick={() => dispatch(setDateRestriction(item))}
            />
          );
        })}
        <SegmentButton
          item={"10/21/2024"}
          value={""}
          icon={calendar}
          disabled={true}
          onClick={() => console.log("Date clicked")}
        />
      </IonSegment>
    </React.Fragment>
  );
};

export default DateRestriction;
