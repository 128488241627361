/* React */
import React from "react";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setName } from "src/redux/features/custom-cohorts/customCohortsSlice";

/* Component */
import PrimaryInput from "src/components/shared/input/primary-input/PrimaryInput";

/* Interface */
interface CustomCohortNameProps {
  name: string;
}

const CustomCohortName: React.FC<CustomCohortNameProps> = ({ name }) => {
  const dispatch = useDispatch();

  return (
    <PrimaryInput
      type={"text"}
      name={"customCohortName"}
      label={"Cohort name"}
      placeholder={name}
      value={name}
      disabled={false}
      onChange={(name: string, value: string) => dispatch(setName(value))}
    />
  );
};

export default CustomCohortName;
