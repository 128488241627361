/* CustomCohorts.ts */

/* Interface(s) */
import { DateRestrictionType } from "src/shared/interfaces/custom-cohorts/DateRestriction";

export enum EntityType {
  BOOLEAN = "boolean",
  NUMERIC = "numeric",
  CONCEPT = "concept",
  UNSUPPORTED = "unsupported",
}

export interface CustomCohortsProps {
  name: string;
  entity: EntityProps;
  dateRestriction: DateRestrictionType;
  value: ValueType;
  customCohortsStr?: string;
}

export interface EntityProps {
  name: string;
  type: EntityType;
  options: boolean[] | string[];
}

export type ValueType = boolean | string;

export interface CustomCohortsStrProps {
  [key: string]: {
    entity_name: string;
    date_restriction: string;
    value: boolean | string;
  };
}
