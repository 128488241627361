/* axios-interceptor.ts */

/* Cookies */
import Cookies from "js-cookie";

/* Environment */
const { VITE_API_PREFIX } = import.meta.env;

/* Axios */
import axios, { AxiosInstance } from "axios";

/**
 * For local development, VITE_API_PREFIX is set to 'http://localhost:8000/api/v1'
 * Otherwise, prefix is set to '/api' (hardcoded)
 **/

/* Interface */
import { TokenType } from "src/shared/interfaces/api/Token";

const axiosInterceptor: AxiosInstance = axios.create({
  baseURL: VITE_API_PREFIX ? VITE_API_PREFIX : "/api",
  timeout: 30000,
});

axiosInterceptor.interceptors.request.use((config) => {
  const access_token = Cookies.get(TokenType.ACCESS_TOKEN);
  const refresh_token = Cookies.get(TokenType.REFRESH_TOKEN);

  if (config.url === "/refresh_token" || config.url === "/logout") {
    if (refresh_token) {
      config.headers.Authorization = `Bearer ${refresh_token}`;
    }
  } else {
    if (access_token) {
      config.headers.Authorization = `Bearer ${access_token}`;
    }
  }
  return config;
});

export default axiosInterceptor;
