/* React */
import React from "react";

/* Ionic */
import { key, person } from "ionicons/icons";

/* React-Redux */
import { useDispatch } from "react-redux";
import { AppDispatch } from "src/redux/store";

/* Thunk */
import { loginWithCredentials } from "src/redux/thunks/auth/authThunk";

/* React-Hook-Form */
import { useForm } from "react-hook-form";

/* Utils */
import useCredentials from "src/utils/hooks/useCredentials";

/* Components */
import ProviderContainer from "src/components/login/provider-container/ProviderContainer";
import PrimaryInput from "src/components/shared/input/primary-input/PrimaryInput";
import PrimaryButton from "src/components/shared/buttons/primary-button/PrimaryButton";

/* Interface */
import { BaseLoginRequest } from "src/shared/interfaces/api/Login";

const FirstFactor: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const {
    register,
    formState: { errors },
    clearErrors,
    setValue,
    trigger,
    handleSubmit,
  } = useForm();

  const initialCredentials: BaseLoginRequest = {
    usernameOrEmail: "",
    password: "",
  };

  const { credentials, handleCredentials } = useCredentials({
    initialCredentials,
    setValue,
    trigger,
  });

  /**
   * User can login with either username or email
   * To distinguish between those input values, we check if the value contains an'@' --> email
   **/
  const validateUsernameOrEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const usernameRegex = /^[a-zA-Z0-9_]{3,20}$/;

    // check if value includes '@'
    if (value.includes("@")) {
      if (emailRegex.test(value)) {
        return true;
      } else {
        return "Email is invalid";
      }
    } else {
      if (usernameRegex.test(value)) {
        return true;
      } else {
        return "Username is invalid";
      }
    }
  };

  const onSubmit = () => {
    dispatch(loginWithCredentials(credentials));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <PrimaryInput
        register={register("usernameOrEmail", {
          required: "Username or email required",
          validate: (value) => validateUsernameOrEmail(value),
        })}
        errors={errors}
        clearErrors={clearErrors}
        type={"text"}
        name={"usernameOrEmail"}
        placeholder={"Username or email"}
        value={credentials.usernameOrEmail}
        icon={person}
        onChange={handleCredentials}
      />
      <PrimaryInput
        register={register("password", { required: "Password required" })}
        errors={errors}
        clearErrors={clearErrors}
        type={"password"}
        name={"password"}
        placeholder={"Password"}
        value={credentials.password}
        icon={key}
        onChange={handleCredentials}
      />
      <PrimaryButton
        type={"submit"}
        ariaLabel={"Login"}
        label={"Login"}
        disabled={
          credentials.usernameOrEmail.length === 0 &&
          credentials.password.length === 0
        }
      />
      <ProviderContainer />
    </form>
  );
};

export default FirstFactor;
