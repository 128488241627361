/* descriptiveStatisticsSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Thunk */
import { fetchDescriptiveStatistics } from "src/redux/thunks/data/descriptiveStatisticsThunk";

/* Interfaces */
import { BaseSliceProps } from "src/shared/interfaces/common/Slice";
import { BaseChartProps } from "src/shared/interfaces/charts/BaseChart";
import { BoxplotProps } from "src/shared/interfaces/charts/Boxplot";
import { BarchartProps } from "src/shared/interfaces/charts/Barchart";
import { HistogramProps } from "src/shared/interfaces/charts/Histogram";
import { BaseErrorProps } from "src/shared/interfaces/common/Error";

const initialState: BaseSliceProps<
  BaseChartProps<(BarchartProps | HistogramProps | BoxplotProps)[]>
> = {
  data: undefined,
  isLoading: false,
  isUpdating: false,
  error: undefined,
};

export const descriptiveStatisticsSlice = createSlice({
  name: "descriptiveStatistics",
  initialState: initialState,
  reducers: {
    clearError: (state) => {
      state.error = undefined;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDescriptiveStatistics.pending, (state) => {
      if (state.data === undefined) {
        state.isLoading = true;
      } else {
        state.isUpdating = true;
      }
    });
    builder.addCase(
      fetchDescriptiveStatistics.rejected,
      (state, action: PayloadAction<BaseErrorProps | undefined>) => {
        state.isLoading = true;
        state.error = {
          showError: action.payload!.showError,
          message: action.payload!.message,
        };
      }
    );
    builder.addCase(
      fetchDescriptiveStatistics.fulfilled,
      (
        state,
        action: PayloadAction<
          BaseChartProps<(BarchartProps | HistogramProps | BoxplotProps)[]>
        >
      ) => {
        state.data = action.payload;
        state.isLoading = false;
        state.isUpdating = false;
        state.error = undefined;
      }
    );
  },
});

/* Action */
export const { clearError } = descriptiveStatisticsSlice.actions;

/* Reducer */
export default descriptiveStatisticsSlice.reducer;
