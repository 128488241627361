/* React */
import React, { useRef, useState } from "react";

/* Ionic */
import { IonItem, IonIcon } from "@ionic/react";
import { chevronUp, chevronDown } from "ionicons/icons";

/* Components */
import LabelPrimary from "src/components/shared/labels/label-primary/LabelPrimary";
import ItemsContainer from "src/components/shared/select/items-container/ItemsContainer";

/* Stylesheet */
import styles from "./Select.module.scss";
import useClickOutsideHandler from "src/utils/hooks/useClickOutsideHandler";

/* Interface */
interface SelectProps<T> {
  label?: string;
  value: string;
  items: T[];
  onClick: (item: T) => void;
}

// extends ensures that the generic type T has a property called name
const Select = <T extends { name: string }>({
  label,
  value,
  items,
  onClick,
}: SelectProps<T>) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [showItems, setShowItems] = useState<boolean>(false);

  const handleShowItems = (value: boolean) => {
    setShowItems(value);
  };

  useClickOutsideHandler({ ref: dropdownRef, onClick: handleShowItems });

  return (
    <div ref={dropdownRef} className={styles.select}>
      {label ? (
        <LabelPrimary
          scssProps={{
            margin: "42px 0px 16px 16px",
            color: "var(--ion-color-text-primary)",
          }}
          label={label}
        />
      ) : undefined}
      <IonItem
        style={{
          "--background": showItems ? "#f2f5fa" : undefined,
          borderRadius: showItems ? "8px 8px 0px 0px" : undefined,
        }}
        className={styles.item}
        lines={"none"}
        button
        onClick={() => handleShowItems(!showItems)}
      >
        <div className={styles.value}>{value}</div>
        <IonIcon
          className={styles.icon}
          icon={showItems ? chevronUp : chevronDown}
          slot="end"
        />
      </IonItem>
      {showItems ? (
        <ItemsContainer
          items={items}
          handleShowItems={handleShowItems}
          onClick={onClick}
        />
      ) : undefined}
    </div>
  );
};

export default Select;
