/* React */
import React from "react";

/* Component */
import LabelPrimary from "src/components/shared/labels/label-primary/LabelPrimary";
import BooleanContainer from "src/components/insights/custom-cohorts/query-builder/value-selector/boolean-container/BooleanContainer";

/* Stylesheet */
import styles from "./ValueSelector.module.scss";

/* Interfaces */
import {
  EntityType,
  EntityProps,
  ValueType,
} from "src/shared/interfaces/api/CustomCohorts";

interface ValueSelectorProps {
  entity: EntityProps;
  value: ValueType;
}

const ValueSelector: React.FC<ValueSelectorProps> = ({ entity, value }) => {
  const componentMapper = (entity: EntityProps) => {
    switch (entity.type) {
      case EntityType.BOOLEAN:
        return (
          <BooleanContainer
            options={entity.options as boolean[]}
            value={value as boolean}
          />
        );
      case EntityType.NUMERIC:
        return <div>Numeric</div>;
      case EntityType.UNSUPPORTED:
        return <div>Not supported</div>;
    }
  };

  return (
    <div className={styles.value_selector}>
      <LabelPrimary
        scssProps={{
          margin: "42px 0px 16px 16px",
          color: "var(--ion-color-text-primary)",
        }}
        label={"Value"}
      />
      {componentMapper(entity)}
    </div>
  );
};

export default ValueSelector;
