/* React */
import React, { useRef } from "react";

/* Ionic */
import { person } from "ionicons/icons";

/* React-Redux */
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import useResizeObserver from "src/utils/hooks/useResizeObserver";
import useBasicChartTooltip from "src/utils/hooks/tooltip/useBasicChartTooltip";
import { config, barchartLayout } from "src/utils/config/chart-config";
import { createLayout } from "src/utils/helper/chart-helper";
import { createLegendInformation } from "src/utils/helper/legend";

/* Component(s) */
import ChartTooltip from "src/components/insights/charts/chart-tooltip/ChartTooltip";
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Stylesheet */
import styles from "./Barchart.module.scss";

/* Interface(s) */
import { ChartType } from "src/shared/interfaces/charts/BaseChart";
import { BaseChartProps } from "src/shared/interfaces/charts/BaseChart";
import { BarchartProps } from "src/shared/interfaces/charts/Barchart";
import { ChartLabelProps } from "src/shared/interfaces/common/ChartLabel";

const Barchart: React.FC<{
  index: number;
  stacked: boolean;
  data: BaseChartProps<BarchartProps[]>;
}> = ({ index, stacked, data }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const offset = useResizeObserver(ref);
  const { tooltip, handleOnHover, handleOnUnhover } =
    useBasicChartTooltip(offset);

  const insightsFilterState = useSelector(
    (state: RootState) => state.insightsFilter
  );

  const { features } = insightsFilterState;

  const barchartData: BarchartProps[] = data.data
    .filter((data: BarchartProps) => data.type === ChartType.BAR)
    .map((data: BarchartProps, index: number) => {
      return {
        ...data,
        ...createLayout(index),
      };
    });

  const barchart = (
    <div data-testid="barchart" ref={ref}>
      <Plot
        className={styles.barchart}
        data={stacked ? barchartData : [barchartData[index]]}
        config={config}
        layout={barchartLayout}
        useResizeHandler={true}
        onHover={(event) => handleOnHover(event)}
        onUnhover={handleOnUnhover}
      />
      <ChartTooltip tooltip={tooltip} />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    label: { icon: person, total: data.total!, name: "Patients" },
    featureOrCohort: { name: features?.value },
    chart: barchart,
    legend: createLegendInformation(barchartData),
  };

  return <ChartWrapper label={chartLabel}>{barchart}</ChartWrapper>;
};

export default Barchart;
