/* React */
import React from "react";

/* Ionic */
import { IonGrid, IonRow, IonCol } from "@ionic/react";

/* Swiper */
import { SwiperClass } from "swiper/react";

/* Component */
import Cohort from "src/components/shared/swiper/swiper-controls/cohort/Cohort";
import BulletContainer from "src/components/shared/swiper/swiper-controls/bullet-container/BulletContainer";
import Pagination from "src/components/shared/swiper/swiper-controls/pagination/Pagination";

/* Stylesheet */
import styles from "./SwiperControls.module.scss";

/* interfacess */
interface SwiperControlsProps {
  scssProps?: ScssProps;
  swiperInstance?: SwiperClass | null;
  stacked: boolean;
  pagination?: boolean;
  length: number;
  activeIndex: number;
  name?: string | undefined;
  currentPage?: number;
  disabled?: DisabledProps;
  handlePrevPage?: () => void;
  handleNextPage?: () => void;
}

interface DisabledProps {
  prevDisabled: boolean;
  nextDisabled: boolean;
}

interface ScssProps {
  padding?: string;
  height?: string;
}

const SwiperControls: React.FC<SwiperControlsProps> = ({
  scssProps,
  swiperInstance,
  stacked,
  pagination,
  length,
  activeIndex,
  name,
  currentPage,
  disabled,
  handlePrevPage,
  handleNextPage,
}) => {
  return (
    <IonGrid
      style={{ padding: scssProps?.padding, height: scssProps?.height }}
      className={styles.swiper_controls}
    >
      <IonRow className={styles.row}>
        <IonCol className={`${styles.col}`} size="2">
          {name !== undefined ? <Cohort name={name} /> : undefined}
        </IonCol>
        <IonCol
          className={`${styles.col} ${styles.bullet_container_col}`}
          size="8"
        >
          {length > 1 && stacked ? (
            <BulletContainer
              swiperInstance={swiperInstance}
              length={length}
              activeIndex={activeIndex}
            />
          ) : undefined}
        </IonCol>
        <IonCol className={`${styles.col} ${styles.pagination_col}`} size="2">
          {pagination ? (
            <Pagination
              disabled={disabled!}
              currentPage={currentPage!}
              handlePrevPage={handlePrevPage}
              handleNextPage={handleNextPage}
            />
          ) : undefined}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default SwiperControls;
