/* React */
import React, { useRef } from "react";

/* Plotly */
import Plot from "react-plotly.js";

/* Utils */
import useResizeObserver from "src/utils/hooks/useResizeObserver";
import useBasicChartTooltip from "src/utils/hooks/tooltip/useBasicChartTooltip";
import { config, boxplotLayout } from "src/utils/config/chart-config";
import { createBoxplotLayout } from "src/utils/helper/chart-helper";
import { createLegendInformation } from "src/utils/helper/legend";

/* Component(s) */
import ChartTooltip from "src/components/insights/charts/chart-tooltip/ChartTooltip";
import ChartWrapper from "src/utils/wrapper/chart-wrapper/ChartWrapper";

/* Stylesheet */
import styles from "./Boxplot.module.scss";

/* Interface(s) */
import {
  ChartType,
  BaseChartProps,
} from "src/shared/interfaces/charts/BaseChart";

import { BoxplotProps } from "src/shared/interfaces/charts/Boxplot";
import { ChartLabelProps } from "src/shared/interfaces/common/ChartLabel";

const BoxPlot: React.FC<{
  index: number;
  stacked: boolean;
  data: BaseChartProps<BoxplotProps[]>;
}> = ({ index, stacked, data }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const offset = useResizeObserver(ref);
  const { tooltip, handleOnHover, handleOnUnhover } =
    useBasicChartTooltip(offset);

  const boxplotData = data.data
    .filter((data: BoxplotProps) => data.type === ChartType.BOX)
    .map((data: BoxplotProps, index: number) => {
      const { y, ...rest } = data;
      return {
        ...rest,
        // to show boxplot horizontally instead of vertically, values of x and y are swapped
        x: y,
        ...createBoxplotLayout(index),
      };
    });

  const boxplot = (
    <div ref={ref} data-testid="boxplot">
      <Plot
        className={styles.boxplot}
        data={stacked ? boxplotData : [boxplotData[index]]}
        config={config}
        layout={boxplotLayout}
        useResizeHandler={true}
        onHover={(event) => {
          handleOnHover(event);
        }}
        onUnhover={handleOnUnhover}
      />
      <ChartTooltip tooltip={tooltip} />
    </div>
  );

  const chartLabel: ChartLabelProps = {
    chart: boxplot,
    legend: createLegendInformation(boxplotData),
  };

  return <ChartWrapper label={chartLabel}>{boxplot}</ChartWrapper>;
};

export default BoxPlot;
