/* InsightsFilter.ts */

export enum FilterType {
  MAIN_CONDITION = "mainCondition",
  FEATURES = "features",
  COHORTS = "cohorts",
  FIRST_YEAR = "firstYear",
  LAST_YEAR = "lastYear",
}

export enum FilterComponentType {
  DROPDOWN = "dropdown",
  SLIDER = "slider",
}

export interface InsightsFilterProps {
  features: FilterProps<string>;
  cohorts: FilterProps<string | undefined>;
  firstYear: FilterProps<string | undefined>;
  lastYear: FilterProps<string | undefined>;
}

export interface FilterProps<T> {
  value: T;
  items?: string[];
  initialValue?: string;
  disabled?: boolean;
  componentType?: string;
}
