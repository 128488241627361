/* React */
import React from "react";

/* React-Tooltip */
// import { Tooltip } from "react-tooltip";

/* Ionic */
import { IonGrid, IonRow, IonCol } from "@ionic/react";

/* Utils */
import { capitalizeCamelCaseFirstLetter } from "src/utils/helper/string-transformation";

/* Stylesheet */
import styles from "./TableSlide.module.scss";

/* Interface */
import { TableProps } from "src/shared/interfaces/charts/Table";

interface TableSlideProps<T> {
  data: TableProps<T>[];
  startIndex: number;
  endIndex: number;
}

const TableSlide = <T,>({ data, startIndex, endIndex }: TableSlideProps<T>) => {
  const pageData: TableProps<T>[] = data.slice(startIndex, endIndex);
  const objKeys: string[] = Object.keys(data[0]);
  const isEven: boolean = objKeys.length % 2 === 0;

  return (
    <>
      <IonGrid className={styles.table_slide}>
        {objKeys.map((key, rowIndex) => {
          return (
            <IonRow
              key={rowIndex}
              style={{
                color:
                  rowIndex === 0 ? "#ffffff" : "var(--ion-color-text-primary)",
                background:
                  rowIndex === 0
                    ? "var(--ion-gradient-secondary)"
                    : rowIndex % 2 === 0
                    ? isEven
                      ? undefined
                      : "#ffffff"
                    : isEven
                    ? "#ffffff"
                    : undefined,
              }}
              className={styles.row}
            >
              <IonCol className={`${styles.col} ${styles.label_col}`} size="2">
                <div className={styles.label}>
                  {capitalizeCamelCaseFirstLetter(key)}
                </div>
              </IonCol>
              {pageData.map((items, colIndex) => {
                return (
                  <IonCol
                    key={colIndex}
                    className={`${styles.col} ${styles.value_col}`}
                    size="2"
                  >
                    <div
                      data-tooltip-id="item"
                      // data-tooltip-content={`Value ${items[key]}`}
                      className={styles.item}
                    >
                      {items[key]}
                    </div>
                  </IonCol>
                );
              })}
            </IonRow>
          );
        })}
        {/* <Tooltip id="item" className={styles.tooltip} place="top">
          <div className={styles.label}>Test Tooltip</div>
        </Tooltip> */}
      </IonGrid>
    </>
  );
};

export default TableSlide;
