/* mainConditionSlice.ts */

/* Redux-Toolkit */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/* Thunk */
import { fetchMainConditions } from "src/redux/thunks/options/mainConditionThunk";

/* Interface */
import { FilterProps } from "src/shared/interfaces/common/InsightsFilter";

const initialState: FilterProps<string | undefined> = {
  value: undefined,
  initialValue: undefined,
  items: [],
  disabled: false,
};

export const mainConditionSlice = createSlice({
  name: "mainCondition",
  initialState: initialState,
  reducers: {
    setMainCondition: (state, action: PayloadAction<string>) => {
      state.value = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchMainConditions.fulfilled,
      (state, action: PayloadAction<{ items: string[] }>) => {
        // if value is undefined and items is not empty, set value to the first item
        if (!state.value) {
          state.value = action.payload.items[0];
          state.initialValue = action.payload.items[0];
        }
        state.items = action.payload.items;
      }
    );
  },
});

/* Action */
export const { setMainCondition } = mainConditionSlice.actions;

/* Reducer */
export default mainConditionSlice.reducer;
