/* useBasicChartTooltip.ts */

/**
 * @file useBasicChartTooltip.ts
 *
 * @description
 *
 * useBasicChartTooltip() provides a custom hook that handles logic for displaying a custom tooltip
 * for basic charts like:
 *
 * - Barchart
 * - Histogram
 * - Boxplot
 * - Scatter (Survival Plot)
 **/

/* React */
import React, { useState } from "react";

/* Utils */
import { createLabel } from "src/utils/helper/chart-helper";

/* Interface(s) */
import {
  OffsetProps,
  ContentProps,
  EventProps,
  PointProps,
  TooltipProps,
} from "src/shared/interfaces/charts/ChartTooltip";

import { ChartType } from "src/shared/interfaces/charts/BaseChart";

const useBasicChartTooltip = (offset: OffsetProps) => {
  const initialState = {
    isVisible: false,
    reachedTop: false,
    reachedRightEdge: false,
    content: [],
    x: 0,
    y: 0,
  };

  const [tooltip, setTooltip] = useState<TooltipProps>(initialState);

  const handleOnHover = (event: EventProps) => {
    const points: ContentProps[] = [];
    const { x0, x1, y0, y1 } = event.points[0].bbox;
    const { type, orientation } = event.points[0].data;
    const { height, width } = offset;

    const reachedTop: boolean = height - y0 > 186;
    const reachedRightEdge: boolean = width - x1 < 120;

    event.points.map((point: PointProps, index: number) => {
      const { type, name, marker } = point.data;

      points.push({
        label: name,
        color: marker.color,
        hovertext: createLabel(
          index,
          type,
          orientation,
          event.points.length,
          point.x,
          point.y
        ),
      });
    });
    setTooltip({
      isVisible: true,
      reachedTop: orientation === "h" && reachedTop,
      reachedRightEdge: reachedRightEdge,
      content: points,
      x:
        type === ChartType.BOX && orientation === "h"
          ? event.points[(event.points.length - 1) / 2].bbox.x1 + 12
          : reachedRightEdge
          ? x0
          : x1 + 12,
      y:
        type === ChartType.BOX
          ? event.points[(event.points.length - 1) / 2].bbox.y1
          : y1,
    });
  };

  const handleOnUnhover = () => {
    setTooltip(initialState);
  };

  return {
    tooltip,
    handleOnHover,
    handleOnUnhover,
  };
};

export default useBasicChartTooltip;
