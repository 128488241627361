/* React */
import React from "react";

/* Ionic */
import { flash } from "ionicons/icons";

/* React-Redux */
import { useDispatch } from "react-redux";
import { setMainCondition } from "src/redux/features/insights/mainConditionSlice";
import { setInsightsFilterValue } from "src/redux/features/insights/insights-filter/insightsFilterSlice";
import { setShowModal } from "src/redux/features/insights/modalSlice";

/* Component(s) */
import QueryItem from "src/components/insights/query-list-container/query-item/QueryItem";
import PrimaryButton from "src/components/shared/buttons/primary-button/PrimaryButton";

/* Stylesheet */
import styles from "./QueryList.module.scss";

/* Interfaces */
import {
  FilterProps,
  InsightsFilterProps,
} from "src/shared/interfaces/common/InsightsFilter";

import { setCustomCohortsStr } from "src/redux/features/custom-cohorts/customCohortsSlice";
import { CustomCohortsProps } from "src/shared/interfaces/api/CustomCohorts";

interface QueryListProps {
  states: {
    mainCondition: FilterProps<string | undefined>;
    insightsFilter: InsightsFilterProps;
    customCohorts: CustomCohortsProps;
  };
}

const QueryList: React.FC<QueryListProps> = ({ states }) => {
  const dispatch = useDispatch();

  return (
    <div className={styles.query_list}>
      <QueryItem
        value={states.mainCondition.value}
        disabled={
          states.mainCondition.value === states.mainCondition.initialValue
        }
        onClick={() =>
          dispatch(setMainCondition(states.mainCondition.initialValue!))
        }
      />
      {Object.keys(states.insightsFilter).map((key: string, index: number) => {
        const typedKey = key as keyof InsightsFilterProps;
        return (
          <QueryItem
            key={index}
            value={states.insightsFilter[typedKey].value}
            disabled={
              states.insightsFilter[typedKey].value ===
              states.insightsFilter[typedKey].initialValue
            }
            onClick={() =>
              dispatch(
                setInsightsFilterValue({
                  key: typedKey,
                  value: states.insightsFilter[typedKey].initialValue!,
                })
              )
            }
          />
        );
      })}
      {states.customCohorts.customCohortsStr ? (
        <QueryItem
          value={states.customCohorts.name}
          disabled={false}
          onClick={() => dispatch(setCustomCohortsStr(undefined))}
        />
      ) : undefined}
      <PrimaryButton
        scssProps={{
          marginLeft: "auto",
          height: "42px",
        }}
        icon={flash}
        type={"button"}
        label={"Custom Cohorts"}
        ariaLabel={"Custom Cohorts"}
        onClick={() => dispatch(setShowModal(true))}
      />
    </div>
  );
};

export default QueryList;
