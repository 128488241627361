/* React */
import React, { useEffect } from "react";

/* Ionic */
import { IonGrid, IonRow, IonCol } from "@ionic/react";

/* Redux-Toolkit */
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "src/redux/store";

/* Thunk */
import { fetchFeatureTypes } from "src/redux/thunks/options/featureTypesThunk";

/* Components */
import CustomCohortName from "src/components/insights/custom-cohorts/query-builder/custom-cohort-name/CustomCohortName";
import CustomEntities from "src/components/insights/custom-cohorts/query-builder/custom-entities/CustomEntities";
import DateRestriction from "src/components/insights/custom-cohorts/query-builder/date-restriction/DateRestriction";
import ValueSelector from "src/components/insights/custom-cohorts/query-builder/value-selector/ValueSelector";

/* Stylesheet */
import styles from "./QueryBuilder.module.scss";

const QueryBuilder: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  const mainConditionState = useSelector(
    (state: RootState) => state.mainCondition
  );

  const { value } = mainConditionState;

  const customCohortsState = useSelector(
    (state: RootState) => state.customCohorts
  );

  const {
    name,
    entity,
    dateRestriction,
    value: customCohortValue,
  } = customCohortsState;

  useEffect(() => {
    if (value) {
      dispatch(fetchFeatureTypes(value));
    }
  }, [dispatch, value]);

  const featureTypesState = useSelector(
    (state: RootState) => state.featureTypes
  );

  const { data } = featureTypesState;

  return (
    <IonGrid className={styles.query_builder}>
      <IonRow className={styles.row}>
        <IonCol className={`${styles.col}`}>
          <CustomCohortName name={name} />
          <CustomEntities entity={entity} featureTypes={data!} />
          <DateRestriction restriction={dateRestriction} />
          <ValueSelector entity={entity} value={customCohortValue} />
        </IonCol>
        <IonCol className={`${styles.col}`}></IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default QueryBuilder;
