/* React */
import React, { useState } from "react";

/* Ionic */
import { IonCard } from "@ionic/react";

/* Components */
import ExpandModal from "src/components/insights/charts/chart-toolbar/expand-modal/ExpandModal";
import ChartToolbar from "src/components/insights/charts/chart-toolbar/ChartToolbar";
import Legend from "src/components/insights/charts/legend/Legend";

/* Stylesheet */
import styles from "./ChartWrapper.module.scss";

/* interfacesss */
import { ChartLabelProps } from "src/shared/interfaces/common/ChartLabel";

interface ChartWrapperProps {
  scssProps?: ScssProps;
  label?: ChartLabelProps;
  children: React.ReactNode;
}

interface ScssProps {
  padding?: string;
  margin?: string;
  height?: string;
  boxShadow?: string;
  chartHeight?: string;
}

const ChartWrapper: React.FC<ChartWrapperProps> = ({
  label,
  children,
  scssProps,
}) => {
  // showModal
  const [showModal, setShowModal] = useState(false);

  const handleModal = (value: boolean) => {
    setShowModal(value);
  };

  return (
    <React.Fragment>
      {showModal ? (
        <ExpandModal
          showModal={showModal}
          handleModal={handleModal}
          chart={label?.chart}
          legend={label?.legend}
        />
      ) : undefined}
      <IonCard
        style={{
          padding: scssProps?.padding,
          height: scssProps?.height,
          boxShadow: scssProps?.boxShadow,
        }}
        className={styles.chart_wrapper}
      >
        {label?.chart ? (
          <ChartToolbar
            label={label}
            component={label?.chart}
            handleModal={handleModal}
          />
        ) : undefined}
        <div
          style={{ height: scssProps?.chartHeight }}
          className={styles.chart}
        >
          {children}
        </div>
        {label?.legend ? <Legend legend={label.legend} /> : undefined}
      </IonCard>
    </React.Fragment>
  );
};

export default ChartWrapper;
