/* descriptiveStatisticsThunk.ts */

/* Redux-Toolkit */
import { createAsyncThunk } from "@reduxjs/toolkit";

/* Axios */
import axios from "axios";
import axiosInterceptor from "src/utils/helper/axios-interceptor";

/* Utils */
import { capitalizeCamelCaseFirstLetter } from "src/utils/helper/string-transformation";
import { transformErrorMessage } from "src/utils/helper/error-message";

import {
  defaultCohort,
  defaultStartDate,
  defaultEndDate,
} from "src/utils/helper/insights-filter";

/* Interface(s) */
import { BaseChartProps } from "src/shared/interfaces/charts/BaseChart";
import { BoxplotProps } from "src/shared/interfaces/charts/Boxplot";
import { BarchartProps } from "src/shared/interfaces/charts/Barchart";
import { HistogramProps } from "src/shared/interfaces/charts/Histogram";
import { BaseChartDataRequest } from "src/shared/interfaces/api/ChartData";
import { BaseErrorProps } from "src/shared/interfaces/common/Error";
import { BaseFilterRequest } from "src/shared/interfaces/api/Filters";

export const fetchDescriptiveStatistics = createAsyncThunk<
  BaseChartProps<(BarchartProps | HistogramProps | BoxplotProps)[]>,
  BaseChartDataRequest<BaseFilterRequest>,
  { rejectValue: BaseErrorProps }
>(
  "descriptiveStatistics/fetchDescriptiveStatistics",
  async (
    params: BaseChartDataRequest<BaseFilterRequest>,
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInterceptor.get(
        "/data/cohort_data_statistics",
        {
          params: {
            main_condition: params.mainCondition,
            feature: params.filters.feature,
            cohort: defaultCohort(params.filters.cohort),
            start_date: defaultStartDate(
              params.filters.startDate.value,
              params.filters.startDate.initialValue!
            ),
            end_date: defaultEndDate(
              params.filters.endDate.value,
              params.filters.endDate.initialValue!
            ),
            custom_cohorts_str: params.filters.customCohortsStr!,
          },
        }
      );

      const tmpResponse: BaseChartProps<
        (BarchartProps | HistogramProps | BoxplotProps)[]
      > = {
        ...response.data,
        data: response.data.data.map(
          (data: BarchartProps | HistogramProps | BoxplotProps) => ({
            ...data,
            name: capitalizeCamelCaseFirstLetter(
              data.name ? data.name : "Full sample"
            ),
          })
        ),
        types: response.data.data.map((data: { type: string }) => data.type),
      };

      return tmpResponse;
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const message: string = transformErrorMessage(
          error.response?.data.detail
        );
        return rejectWithValue({ showError: true, message: message });
      }
      return rejectWithValue({
        showError: true,
        message: "An unexpected error occured",
      });
    }
  }
);
