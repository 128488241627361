/* React */
import React from "react";

/* Stylesheet */
import styles from "./LabelContainer.module.scss";

/* Interface(s) */
import { ContentProps } from "src/shared/interfaces/charts/ChartTooltip";

interface LabelContainerProps {
  index: number;
  content: ContentProps;
}

const LabelContainer: React.FC<LabelContainerProps> = ({ index, content }) => {
  const { label, color } = content;
  return label && index === 0 ? (
    <div className={styles.label_container}>
      <div style={{ background: color }} className={styles.color} />
      <div className={styles.name}>{label}</div>
    </div>
  ) : undefined;
};

export default LabelContainer;
