/* store.ts */

/* Redux-Toolkit */
import { configureStore } from "@reduxjs/toolkit";

/* Redux-Persis */
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";

/* Reducers */
import loadingScreenReducer from "src/redux/features/loading-screen/loadingScreenSlice";
import modalReducer from "src/redux/features/insights/modalSlice";
import providerReducer from "src/redux/features/auth/providerSlice";
import authReducer from "src/redux/features/auth/authSlice";
import mainMenuReducer from "src/redux/features/main-menu/mainMenuSlice";
import pageTitleReducer from "src/redux/features/page-title/pageTitleSlice";
import insightsFilterMenuReducer from "src/redux/features/insights/insights-filter/insightsFilterMenuSlice";
import mainConditionReducer from "src/redux/features/insights/mainConditionSlice";
import insightsFilterReducer from "src/redux/features/insights/insights-filter/insightsFilterSlice";
import insightsFilterOptionsReducer from "src/redux/features/insights/insights-filter/insightsFilterOptionsSlice";
import survivalAnalysisOptionsReducer from "src/redux/features/insights-options/survival-analysis/survivalAnalysisOptionsSlice";
import sankeDiagramOptionsReducer from "src/redux/features/insights-options/sankey-diagram/sankeyDiagramOptionsSlice";
import descriptiveStatisticsReducer from "src/redux/features/insights/descriptiveStatisticsSlice";
import survivalAnalysisReducer from "src/redux/features/insights/survivalAnalysisSlice";
import treatmentPatternsReducer from "src/redux/features/insights/treatmentPatternsSlice";
import featureTypesReducer from "src/redux/features/custom-cohorts/featureTypesSlice";
import customCohortsReducer from "src/redux/features/custom-cohorts/customCohortsSlice";

/* Config */
const persistAuthConfig = {
  key: "auth",
  storage,
  whitelist: ["data"],
};

/* Persisted Reducer */
const persistAuthReducer = persistReducer(persistAuthConfig, authReducer);

export const store = configureStore({
  reducer: {
    loadingScreen: loadingScreenReducer,
    modal: modalReducer,
    provider: providerReducer,
    auth: persistAuthReducer,
    mainMenu: mainMenuReducer,
    pageTitle: pageTitleReducer,
    insightsFilterMenu: insightsFilterMenuReducer,
    mainCondition: mainConditionReducer,
    insightsFilter: insightsFilterReducer,
    insightsFilterOptions: insightsFilterOptionsReducer,
    survivalAnalysisOptions: survivalAnalysisOptionsReducer,
    sankeyDiagramOptions: sankeDiagramOptionsReducer,
    descriptiveStatistics: descriptiveStatisticsReducer,
    survivalAnalysis: survivalAnalysisReducer,
    treatmentPatterns: treatmentPatternsReducer,
    featureTypes: featureTypesReducer,
    customCohorts: customCohortsReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
